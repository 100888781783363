#newsletter {
    padding-top: 144px;
    padding-bottom: 93px;
    background-color: #EDEDED;
    @media (max-width: 1100px) {
        padding-top: 31px;
        padding-bottom: 31px;
    }
    h3 {
        font-family: 'Butler-Bold';
        font-size: 45px;
        line-height: 40px;
        color: #997A5D;
        max-width: 352px;
        margin-left: 5px;
        @media (max-width: 1100px) {
            font-size: 22px;
            line-height: 25px;
            max-width: 75%;
            margin: 0;
            padding-left: 12px;
            margin-bottom: 27px;
        }
    }

    form {
        margin-left: 66px;
        @media (max-width: 1100px) {
            margin-left: 0;
            padding-inline: 24px;
        }
    }

    .col-lg-6,
    .col-lg-12 {
        padding: 0;
    }

    input,
    select {
        width: 100%;
        padding: 25px 20px;
        font-family: 'Raleway';
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        color: #464646;
        border: none;
        max-width: 402px;
        margin-bottom: 4px;
        @media (max-width: 1100px) {
            margin-bottom: 9px;
        }
    }

    input::placeholder,
    select::placeholder {
        font-family: 'Raleway';
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        color: #464646;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../img/arrow-select.png");
        background-repeat: no-repeat;
        background-position-x: 91%;
        background-position-y: 50%;
    }

    input[type="submit"] {
        font-family: 'Raleway';
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #ffffff;
        background-color: #D2A771;
        transition: .3s ease-in;

        &:hover {
            background-color: #997A5D;
            transition: .3s ease-in;
        }
    }

    .check-box {
        margin-top: 28px;
        display: flex;
        gap: 16px;

        p {
            font-family: 'Raleway';
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            font-style: italic;
            color: #464646;
            margin-bottom: 0;
            @media (max-width: 1100px) {
                text-align: left;
                max-width: 60%;
                margin-bottom: 24px;
            }
            a {
                color: #464646;
            }
        }
    }

    input[type="checkbox"] {
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 54px;
        height: 29px;
        background: #ffffff;
        border-radius: 50px;
        cursor: pointer;
        transition: 0.4s;
        padding: 0;
    }

    input:checked[type="checkbox"] {
        background: #D2A771;
    }

    input[type="checkbox"]::after {
        position: absolute;
        content: "";
        width: 21px;
        height: 21px;
        top: 4px;
        left: 6px;
        background: #997A5D;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
        transition: 0.4s;
    }

    input:checked[type="checkbox"]::after {
        left: 50%;
    }
}
