// body.app {
//     background: url('../img/lugar.png') top center no-repeat;
// }

.banner-interna {
    padding: 180px 0 91px 0;
    position: relative;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .heading {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: flex;
        flex-direction: column;
    }

    .heading>div {
        overflow: hidden;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(2)>p {
        -webkit-animation-delay: 0.05s;
        animation-delay: 0.05s;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(3)>p {
        -webkit-animation-delay: 0.075s;
        animation-delay: 0.075s;
    }

    .slide-up {
        -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
    }

    @-webkit-keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    .container {
        padding-left: 20px;
        z-index: 2;
        position: inherit;
    }

    h1 {
        font-family: 'Bodoni Moda';
        font-weight: 700;
        font-size: 66px;
        line-height: 60px;
        color: #FFFFFF;
        margin-bottom: 42px;
        @media (max-width: 1100px) {
            font: normal normal bold 30px/35px "Butler-Regular";
        }
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 27px;
        line-height: 26px;
        color: #FFFFFF;
        @media (max-width: 1100px) {
            font-size:  14px;
            line-height: 20px;
        }
    }

    .scroll-link {
        display: inline-block;
        margin-top: 80px;
        transition: .3s ease-in;

        &:hover {
            svg path {
                fill: #d9a521;
                transition: .3s ease-in;
            }
        }
    }

    .layer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #00000094;
        z-index: 1;
    }
}

.content-image {
    display: flex;
    width: 100%;
    height: 627px;
    overflow: hidden;
    position: relative;

}

#lugar {

    .conteudo-section {
        padding: 90px 0;
        position: relative;

        .bloco-imagem {
            max-width: 968px;
            max-height: 643px;
        }

        .bloco-texto {
            position: absolute;
            right: 6px;
            width: 53%;
            padding: 94px 123px 131px 121px;
            top: 163px;
            background-color: #FFFFFF;

            .title-interna {
                margin-bottom: 65px;
            }

            p {
                max-width: 381px;
            }
        }
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #707070;
        @media (max-width: 1100px) {
            font-size:  14px;
            line-height:  24px;
        }
    }

    .section1 {
        padding: 93px 0 98px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 32px;
            padding-bottom: 42px;
            padding-inline: 12px;
        }
        .title-interna {
            padding-left: 123px;
            margin-top: 70px;
            padding-top: 94px;
            margin-bottom: 68px;
            color: #997A5D;
            @media (max-width: 1100px) {
                margin: 0;
                padding: 0;
                margin-bottom: 24px;
                margin-top: 33px;
            }
        }

        p {
            padding-left: 123px;
            max-width: 540px;
            margin-bottom: 32px;
            @media (max-width: 1100px) {
                margin: 0;
                padding: 0;
            }
        }

        .imagem-section {
            width: 1280px;
            height: 770px;
            position: absolute;
            left: -114px;
            overflow: hidden;
            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                height: 207px;
                left: inherit;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .conteudo-section {
            margin-left: 31px;
            padding: 0;
            position: relative;
            background: #ffffff;
            z-index: 99;
            padding-bottom: 53px;
            @media (max-width: 1100px) {
                padding-bottom: 0;
                margin-left: 0;
            }
        }
    }

    .section2 {
        padding: 128px 0 88px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        background-attachment: fixed;
        @media (max-width: 1100px) {
            padding-top: 32px;
            padding-bottom: 32px;
        }
        .container {
            position: relative;
            z-index: 2;
        }

        .title-interna2,
        p {
            color: #ffffff;
            text-align: center;
        }

        .title-interna2 {
            margin-bottom: 51px;
            @media (max-width: 1100px) {

            }
        }

        p {
            max-width: 801px;
            margin: 0 auto;
            letter-spacing: 1px;
            margin-bottom: 30px;
            @media (max-width: 1100px) {
                margin-bottom: 0;
            }
        }

        .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
    }

    .section3 {
        padding: 119px 0 9px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 34px;
            padding-bottom: 0;
            padding-inline: 12px;
        }
        .title-interna {
            padding-left: 109px;
            margin-top: 15px;
            padding-top: 67px;
            margin-bottom: 55px;
            color: #997A5D;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                margin-top: 34px;
                margin-bottom: 24px;
            }
        }

        p {
            padding-left: 112px;
            max-width: 556px;
            margin-bottom: 32px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
            }
        }

        .imagem-section {
            width: 794px;
            height: 671px;
            position: absolute;
            right: 376px;
            overflow: hidden;

            @media(max-width: 1600px) {
                width: 794px;
                height: 671px;
                right: 100px;
            }

            @media (max-width: 1100px) {
                position: relative;
                left: inherit;
                width: 100%;
                height: 207px;
                right: inherit;
            }
            figure {
                height: 100%;
                margin: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .conteudo-section {
            margin-left: 31px;
            padding: 0;
            position: relative;
            background: #ffffff;
            z-index: 99;
            padding-bottom: 1px;
            margin-top: 44px;
            max-width: 765px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
            }
        }
    }

    #explore {

        h3 {
            line-height: 62px;
            color: #997A5D;
            margin-bottom: 39px;

            small {
                line-height: 62px;
                color: #997A5D;
            }
            @media (max-width: 1100px) {
                font-size:  22px;
                line-height: 27px;
                small{
                    font-size:  17px;
                    line-height:  27px;
                }
            }
        }

        .btn-interna {
            background-color: #997A5D;

            &:hover {
                background-color: #25224D;
            }
        }
    }
}
