@charset "UTF-8";
/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

@font-face {
  font-family: "Butler-Bold";
  src: url("../fonts/butler-font/Butler_Bold.otf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Butler-Regular";
  src: url("../fonts/butler-font/Butler_Regular.otf");
  font-style: normal;
  font-display: swap;
}
* {
  font-family: "Raleway";
  font-weight: 400;
}

body {
  zoom: 0.8;
}
@media (max-width: 768px) {
  body {
    zoom: 1;
  }
}

.form-group {
  position: relative;
  margin: 0;
}

input.error,
textarea.error,
select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

img {
  max-width: 100%;
}

.title-interna,
.title-interna strong {
  font-family: "Butler-Bold";
  font-size: 54px;
  line-height: 40px;
  color: #25224D;
}
@media (max-width: 768px) {
  .title-interna,
.title-interna strong {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
  }
}

.title-interna2 {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 55px;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .title-interna2 {
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
  }
}

.btn-interna {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 55px 21px;
  padding: 21px 55px;
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
.btn-interna:hover {
  transition: 0.3s ease-in;
  background-color: #D2A771;
  color: #ffffff;
}

/* Zoom In #1 */
.hover01 figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
}

.hover01 figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.3s ease-in-out !important;
}

/*Shine*/
.hover14 figure {
  position: relative;
  margin: 0;
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s !important;
  animation: shine 0.75s !important;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
#open_preferences_center {
  display: none;
}

header {
  background-color: #ffffff;
}
header.fixed {
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 999999;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.0509803922);
}
@media (max-width: 1100px) {
  header.fixed {
    border-bottom: 0.5px solid #707070;
  }
}
header.fixed .topo .logo {
  max-width: 108px;
}
@media (max-width: 1100px) {
  header.fixed .topo .logo {
    max-width: 76px;
  }
}
header.fixed .topo .menu-icon {
  margin-top: 28px;
}
@media (max-width: 1100px) {
  header.fixed .topo .menu-icon {
    margin-top: 0;
  }
}
header .topo {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  margin-bottom: 6px;
  background-color: #ffffff;
}
@media (max-width: 1100px) {
  header .topo {
    align-items: center;
    padding: 25px 10px;
  }
}
header .topo .btn-reservar {
  display: none;
}
@media (max-width: 1100px) {
  header .topo .btn-reservar {
    display: flex;
    gap: 8.7px;
    justify-content: center;
    align-items: center;
    padding: 10px 26px;
    background: #D9A521;
    text-decoration: none;
    color: #ffffff;
    font-family: "Raleway";
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    height: 42px;
  }
}
header .topo .logo {
  margin-left: 53px;
}
@media (max-width: 1100px) {
  header .topo .logo {
    margin-left: 0;
    max-width: 76px;
  }
}
header .topo .menu-icon {
  margin-top: 48px;
}
@media (max-width: 1100px) {
  header .topo .menu-icon {
    margin-top: 0;
  }
}
header .menu {
  border-top: solid 1px #707070;
  background-color: #ffffff;
}
@media (max-width: 1100px) {
  header .menu {
    display: none;
  }
}
header .menu nav {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
header .menu nav .navbar-nav {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}
header .menu nav .nav-item a {
  text-transform: uppercase;
  font-family: "Butler-Regular";
  font-size: 23px;
  color: #121212;
  line-height: 26px;
  letter-spacing: 2px;
  position: relative;
  transition: 0.3s ease-in;
}
header .menu nav .nav-item a:hover {
  transition: 0.3s ease-in;
  color: #997A5D;
}
header .menu nav .nav-item a.active {
  color: #997A5D;
}
header .menu nav .nav-item a::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 7px;
  top: 43%;
  right: -25px;
  background: url("../img/circle.png") center center no-repeat;
  background-size: cover;
}
header .menu nav .nav-item:last-child a::after {
  display: none;
}
header .box {
  float: left;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5803921569);
  position: absolute;
  width: 100%;
  height: 125vh;
  top: 0;
  right: 0;
  z-index: 999;
}
header .box-inner {
  width: 708px;
  float: right;
  right: 0;
  background: #EAEAEA;
  height: 125vh;
  overflow: auto;
}
@media (max-width: 1100px) {
  header .box-inner {
    width: 100%;
    height: 100dvh;
  }
}
header .box-inner .container {
  background-color: #ffffff;
}
header .box-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
header .box-inner::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
header .box-inner::-webkit-scrollbar-thumb {
  background-color: #997A5D;
}
header .box-inner .topo-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 80px 22px 80px;
}
@media (max-width: 1100px) {
  header .box-inner .topo-menu {
    padding: 25px 10px;
  }
}
@media (max-width: 1100px) {
  header .box-inner .topo-menu .logo-inside {
    max-width: 76px;
  }
}
header .box-inner .topo-menu .slide-toggle {
  margin-right: 15px;
}
header .box-inner .border {
  width: 100%;
  height: 1px;
  background-color: #707070;
}
header .box-inner .desk-menu {
  display: block;
}
@media (max-width: 1100px) {
  header .box-inner .desk-menu {
    display: none;
  }
}
header .box-inner .mobile-menu {
  display: none;
}
@media (max-width: 1100px) {
  header .box-inner .mobile-menu {
    display: block;
  }
}
header .box-inner .links {
  padding: 60px 66px 54px 66px;
  background-color: #ffffff;
  /* ul li {
       &:first-child {
           a {
               color: #997A5D;
           }
       }

       &:last-child {
           a {
               color: #997A5D;
           }
       }
   }*/
}
@media (max-width: 1100px) {
  header .box-inner .links {
    padding: 0;
    padding-top: 34px;
    padding-bottom: 34px;
  }
}
header .box-inner .links ul li a {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  color: #121212;
  margin-bottom: 40px;
  letter-spacing: 2px;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  header .box-inner .links ul li a {
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Butler-Regular";
    text-transform: uppercase;
    margin-bottom: 49px;
  }
}
header .box-inner .links ul li a:hover {
  transition: 0.3s ease-in;
  color: #997A5D;
}
header .box-inner .politicas {
  padding: 92px 90px 143px 90px;
  background-color: #EAEAEA;
}
@media (max-width: 1100px) {
  header .box-inner .politicas {
    padding: 0;
    padding-left: 24px;
    padding-top: 40px;
  }
}
header .box-inner .politicas ul {
  padding-left: 0;
}
@media (max-width: 1100px) {
  header .box-inner .politicas ul {
    margin-bottom: 0;
  }
}
header .box-inner .politicas ul li a {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  color: #121212;
  margin-bottom: 40px;
  letter-spacing: 2px;
  transition: 0.3s ease-in;
}
header .box-inner .politicas ul li a:hover {
  transition: 0.3s ease-in;
  color: #997A5D;
}
@media (max-width: 1100px) {
  header .box-inner .politicas ul li a {
    margin-bottom: 43px;
  }
}
header .box-inner .politicas .btns {
  display: inline-block;
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 21px 38px;
  text-decoration: none;
  margin-top: 83px;
  background-color: #D9A521;
  color: #ffffff;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  header .box-inner .politicas .btns {
    display: none;
  }
}
header .box-inner .politicas .btns:hover {
  transition: 0.3s ease-in;
  background-color: #25224D;
}
header .box-inner .politicas .redes-sociais {
  margin-top: 0px;
  display: flex;
  justify-content: start;
  gap: 40px;
}
header .box-inner .reserva-mobile-menu {
  display: none;
}
@media (max-width: 1100px) {
  header .box-inner .reserva-mobile-menu {
    height: 237px;
    background: #25224D;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    left: -24px;
    width: calc(100% + 24px);
    justify-content: center;
    align-items: center;
  }
}
header .box-inner .reserva-mobile-menu img {
  width: 80px;
  height: 80px;
}
header .box-inner .reserva-mobile-menu a {
  display: flex;
  gap: 8.7px;
  background: #D9A521;
  color: #fff;
  text-decoration: none;
  font: normal normal bold 18px/18px Raleway;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: calc(100% - 48px);
  margin: 0 auto;
}
header .box-inner .redes-menu-mobile {
  display: none;
}
@media (max-width: 1100px) {
  header .box-inner .redes-menu-mobile {
    height: 140px;
    background: #EAEAEA;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 39px;
    left: -24px;
    position: relative;
  }
}
header .slide-toggle {
  cursor: pointer;
}

.reserve-agr {
  position: fixed;
  right: -99px;
  top: 50%;
  z-index: 99;
  vertical-align: middle;
  display: table-cell;
  transform: rotate(-90deg);
  font-family: "Raleway";
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #D9A521;
  padding: 19px 31px 12px 31px;
  text-decoration: none;
  background-color: #25224D;
  z-index: 99;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  .reserve-agr {
    display: none;
  }
}
.reserve-agr svg {
  transform: rotate(90deg);
  margin-right: 15px;
  transition: 0.3s ease-in;
}
.reserve-agr:hover {
  background-color: #D9A521;
  color: #25224D;
  transition: 0.3s ease-in;
}
.reserve-agr:hover svg path {
  fill: #25224D;
  transition: 0.3s ease-in;
}

footer {
  padding-top: 76px;
  background-color: #997A5D;
}
footer .parte1 {
  margin-bottom: 29px;
}
footer .logo-footer {
  margin-top: 4px;
  margin-left: 7px;
  margin-bottom: 71px;
}
footer .frase {
  margin-left: 7px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  max-width: 270px;
  color: #ffffff;
  display: block;
}
@media (max-width: 1100px) {
  footer .frase {
    display: none;
  }
}
footer h4 {
  font-family: "Butler-Bold";
  font-size: 17px;
  line-height: 21px;
  color: #D2A771;
  margin-left: 34px;
  margin-bottom: 35px;
  text-align: left;
}
@media (max-width: 1100px) {
  footer h4 {
    margin-left: 0;
    text-align: center;
    font-size: 22px;
  }
}
footer ul {
  padding-left: 0;
  margin-left: 34px;
  text-align: left;
}
@media (max-width: 1100px) {
  footer ul {
    margin-left: 0;
    margin-bottom: 0;
    text-align: center;
  }
}
footer ul li {
  margin-bottom: 19px;
}
@media (max-width: 1100px) {
  footer ul li {
    margin-bottom: 26px;
  }
}
footer ul li a {
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  footer ul li a {
    font-size: 16px;
  }
}
footer ul li a:hover {
  transition: 0.3s ease-in;
  color: #D2A771;
}
footer ul li label {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  transition: 0.3s ease-in;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}
footer .coluna1 .d-flex {
  gap: 26px;
}
@media (max-width: 1100px) {
  footer .coluna1 .d-flex {
    gap: 0;
  }
}
footer .coluna2 .d-flex {
  gap: 58px;
}
@media (max-width: 1100px) {
  footer .coluna2 .d-flex {
    gap: 40px;
  }
}
footer .coluna2 div:first-child {
  margin-left: 14px;
}
@media (max-width: 1100px) {
  footer .coluna2 div:first-child {
    margin-left: 0;
  }
}
@media (max-width: 1100px) {
  footer .coluna2 {
    margin-top: 29px;
  }
}
footer .parte2 {
  margin-bottom: 79px;
}
@media (max-width: 1100px) {
  footer .parte2 {
    margin-bottom: 50px;
  }
}
footer .btns {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #997A5D;
  padding: 10px;
  text-decoration: none;
  background-color: #ffffff;
  transition: 0.3s ease-in;
  width: 100%;
  text-align: center;
}
@media (max-width: 1100px) {
  footer .btns {
    margin-left: 0;
    text-align: center;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 70px;
    width: 100%;
  }
}
footer .btns:hover {
  background-color: #D2A771;
  color: #ffffff;
  transition: 0.3s ease-in;
}
footer .btns:hover svg path {
  fill: #fff;
}
footer .redes-sociais {
  display: flex;
  justify-content: flex-end;
  gap: 35px;
  align-items: end;
  height: 100%;
}
@media (max-width: 1100px) {
  footer .redes-sociais {
    justify-content: center;
  }
}
footer .redes-sociais a.redes:hover svg path,
footer .redes-sociais a.redes:hover svg ellipse {
  fill: #D2A771;
  transition: 0.3s ease-in;
}
footer .redes-sociais a.youtube:hover svg path {
  stroke: #D2A771 !important;
  transition: 0.3s ease-in;
}
footer .copy {
  padding-top: 33px;
  padding-bottom: 16px;
  background-color: #937457;
}
footer .copy p {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #F5EEE5;
}
@media (max-width: 1100px) {
  footer .copy p {
    margin: 0;
    margin-top: 19px;
    text-align: center;
  }
}
footer .copy a {
  transition: 0.3s ease-in;
}
footer .copy a:hover svg path {
  fill: #25224D;
  transition: 0.3s ease-in;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: #000000;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #000000;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: #000000;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #000000;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, #000000);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

#newsletter {
  padding-top: 144px;
  padding-bottom: 93px;
  background-color: #EDEDED;
}
@media (max-width: 1100px) {
  #newsletter {
    padding-top: 31px;
    padding-bottom: 31px;
  }
}
#newsletter h3 {
  font-family: "Butler-Bold";
  font-size: 45px;
  line-height: 40px;
  color: #997A5D;
  max-width: 352px;
  margin-left: 5px;
}
@media (max-width: 1100px) {
  #newsletter h3 {
    font-size: 22px;
    line-height: 25px;
    max-width: 75%;
    margin: 0;
    padding-left: 12px;
    margin-bottom: 27px;
  }
}
#newsletter form {
  margin-left: 66px;
}
@media (max-width: 1100px) {
  #newsletter form {
    margin-left: 0;
    padding-inline: 24px;
  }
}
#newsletter .col-lg-6,
#newsletter .col-lg-12 {
  padding: 0;
}
#newsletter input,
#newsletter select {
  width: 100%;
  padding: 25px 20px;
  font-family: "Raleway";
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #464646;
  border: none;
  max-width: 402px;
  margin-bottom: 4px;
}
@media (max-width: 1100px) {
  #newsletter input,
#newsletter select {
    margin-bottom: 9px;
  }
}
#newsletter input::placeholder,
#newsletter select::placeholder {
  font-family: "Raleway";
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #464646;
}
#newsletter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../img/arrow-select.png");
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: 50%;
}
#newsletter input[type=submit] {
  font-family: "Raleway";
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  color: #ffffff;
  background-color: #D2A771;
  transition: 0.3s ease-in;
}
#newsletter input[type=submit]:hover {
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
#newsletter .check-box {
  margin-top: 28px;
  display: flex;
  gap: 16px;
}
#newsletter .check-box p {
  font-family: "Raleway";
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  font-style: italic;
  color: #464646;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  #newsletter .check-box p {
    text-align: left;
    max-width: 60%;
    margin-bottom: 24px;
  }
}
#newsletter .check-box p a {
  color: #464646;
}
#newsletter input[type=checkbox] {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 54px;
  height: 29px;
  background: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.4s;
  padding: 0;
}
#newsletter input:checked[type=checkbox] {
  background: #D2A771;
}
#newsletter input[type=checkbox]::after {
  position: absolute;
  content: "";
  width: 21px;
  height: 21px;
  top: 4px;
  left: 6px;
  background: #997A5D;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}
#newsletter input:checked[type=checkbox]::after {
  left: 50%;
}

#explore {
  padding: 75px 0 0 0;
}
@media (max-width: 1100px) {
  #explore {
    padding: 60px 0 0 0;
  }
}
#explore h3 {
  font-family: "Butler-Regular";
  font-size: 70px;
  line-height: 70px;
  color: #25224D;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}
#explore h3 small {
  font-family: "Butler-Regular";
  font-size: 40px;
  line-height: 70px;
  color: #25224D;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #explore h3 {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #explore h3 small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#explore .btn-interna {
  display: flex;
  margin: 0 auto;
  max-width: 315px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #D9A521;
  margin-bottom: 66px;
}
@media (max-width: 1100px) {
  #explore .btn-interna {
    margin-bottom: 44px;
    font: normal normal bold 18px/21px Raleway;
  }
}
#explore .btn-interna svg {
  max-width: 33px;
}
#explore .btn-interna svg path {
  fill: #ffffff;
}
#explore .btn-interna:hover {
  background-color: #25224D;
  color: #ffffff;
}
#explore .btn-interna:hover svg path {
  fill: #ffffff;
}
#explore figure {
  margin: 0;
}

#mapa {
  width: 100%;
  height: 688px;
}
@media (max-width: 1100px) {
  #mapa {
    height: 331px;
  }
}

.banner {
  min-height: 500px;
  padding-top: 110px;
  position: relative;
}
@media (max-width: 1100px) {
  .banner {
    padding-top: 81px;
    padding-bottom: 46px;
    min-height: auto;
  }
}
.banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(90deg, #212020 0%, rgba(33, 32, 32, 0) 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.73;
  z-index: -1;
}
.banner .btn-down-mobile {
  display: none;
}
@media (max-width: 1100px) {
  .banner .btn-down-mobile {
    display: flex;
  }
}
.banner .btn-atrativo:hover {
  transition: 0.3s ease-in;
}
.banner .btn-atrativo:hover:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
.banner .banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
.banner .content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  .banner .content {
    padding-inline: 12px;
  }
}
.banner .content .title {
  text-align: left;
  font: normal normal bold 55px/60px "Butler-Regular";
  letter-spacing: 0px;
  color: #FFFFFF;
}
@media (max-width: 1100px) {
  .banner .content .title {
    font: normal normal bold 30px/35px "Butler-Regular";
  }
}
.banner .content .text {
  text-align: left;
  font-family: "Raleway";
  font-weight: medium;
  letter-spacing: 0px;
  color: #FEFEFE;
  font-size: 22px;
}
@media (max-width: 1100px) {
  .banner .content .text {
    font-size: 14px;
    line-height: 20px;
  }
}

/*------------------------- Containers -----------------------*/
.termsfeed-com---palette-light.termsfeed-com---nb {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1607843137);
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: white;
}

/*-------------------------TEXTS -----------------------*/
/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #997A5D;
  transition: 0.7s;
  color: #fff;
  margin-right: 1rem !important;
}
.termsfeed-com---palette-dark .cc-nb-okagree:hover {
  background: #D2A771;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #fff;
  background: #997A5D;
  margin-right: 1rem !important;
}
.termsfeed-com---palette-dark .cc-nb-reject:hover {
  background: #D2A771;
}

.termsfeed-com---nb-simple {
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 2vh;
  border: 0.2px solid #ffffff;
  transition: 0.4s;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background: #997A5D !important;
  margin-right: 1rem !important;
  transition: 0.3s ease-in;
  border-radius: 0px !important;
  padding: 10px 20px !important;
}
.termsfeed-com---palette-light .cc-nb-okagree:hover {
  background: #D2A771 !important;
  transition: 0.3s ease-in;
}

.termsfeed-com---palette-light .cc-nb-reject {
  background: #997A5D !important;
  margin-right: 1rem !important;
  transition: 0.3s ease-in;
  border-radius: 0px !important;
  padding: 10px 20px !important;
}
.termsfeed-com---palette-light .cc-nb-reject:hover {
  background: #D2A771 !important;
  transition: 0.3s ease-in;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

#page404 {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F4F4;
}
#page404 .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
#page404 .title {
  text-align: left;
  font: normal normal normal 56px/63px "Butler-Regular";
  letter-spacing: 0px;
  color: #D2A771;
}
@media (max-width: 1100px) {
  #page404 .title {
    font-size: 22px;
    line-height: 30px;
  }
}
#page404 .subtitle {
  text-align: center;
  font: normal normal normal 45px/45px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #page404 .subtitle {
    font-size: 22px;
    line-height: 25px;
  }
}
#page404 .text {
  color: var(--unnamed-color-707070);
  text-align: center;
  font: normal normal medium 24px/30px Raleway;
  letter-spacing: 0px;
  color: #707070;
}
@media (max-width: 1100px) {
  #page404 .text {
    font: normal normal medium 14px/30px Raleway;
  }
}
#page404 .backHome {
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 20px 40px;
  background: #997A5D 0% 0% no-repeat padding-box;
  text-decoration: none;
  transition: all 0.3s ease-out;
  border: 1px solid #997A5D;
}
@media (max-width: 1100px) {
  #page404 .backHome {
    width: calc(100% - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#page404 .backHome:hover {
  background-color: #D2A771 !important;
  border-color: #D2A771 !important;
}

#home .banner-home {
  position: relative;
}
#home .banner-home .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  #home .banner-home .heading {
    max-width: 80%;
  }
}
#home .banner-home .heading > div {
  overflow: hidden;
}
#home .banner-home div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
#home .banner-home div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
#home .banner-home div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
#home .banner-home div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
#home .banner-home .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
#home #carouselHome .carousel-indicators {
  margin-left: 0;
  margin-right: 0;
  justify-content: start;
  left: inherit;
  right: inherit;
  bottom: 144px;
  margin-left: 7px;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-indicators {
    bottom: 114px;
    left: 39vw;
  }
}
#home #carouselHome .carousel-indicators [data-bs-target] {
  width: 9px;
  height: 9px;
  border-top: 0;
  border-bottom: 0;
  border-radius: 30px;
  margin-right: 15px;
  margin-left: 0;
  opacity: 1;
}
#home #carouselHome .carousel-indicators .active {
  background-color: #D2A771;
}
#home #carouselHome .carousel-inner {
  height: 755px;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-inner {
    height: 554px;
  }
}
#home #carouselHome .carousel-item {
  height: 100%;
  position: relative;
}
#home #carouselHome .carousel-item .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.53) 0%, rgba(33, 32, 32, 0) 100%);
  z-index: 1;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-item .layer {
    pointer-events: none;
  }
}
#home #carouselHome .carousel-item img {
  height: 100%;
  object-fit: cover;
}
#home #carouselHome .carousel-item .carousel-caption {
  bottom: inherit;
  top: 100px;
  text-align: left;
  left: 13px;
  right: 0;
  z-index: 2;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-item .carousel-caption {
    top: 50px;
  }
}
#home #carouselHome .carousel-item .carousel-caption h2 {
  max-width: 640px;
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 60px;
  margin-bottom: 64px;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-item .carousel-caption h2 {
    text-align: left;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 38px;
  }
}
#home #carouselHome .carousel-item .carousel-caption p {
  max-width: 440px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
}
@media (max-width: 1100px) {
  #home #carouselHome .carousel-item .carousel-caption p {
    font-size: 14px;
    line-height: 24px;
    max-width: 60vw;
  }
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}
@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}
#home #carouselHome .carousel-inner .carousel-item > img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}
#home #carouselHome .carousel-item.active img {
  transition: transform 8000ms linear 0s;
  transform: scale(1.2, 1.2);
}
@media all and (transform-3d), (-webkit-transform-3d) {
  #home #carouselHome .carousel-fade .carousel-inner > .carousel-item.next,
#home #carouselHome .carousel-fade .carousel-inner > .carousel-item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #home #carouselHome .carousel-fade .carousel-inner > .carousel-item.prev,
#home #carouselHome .carousel-fade .carousel-inner > .carousel-item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #home #carouselHome .carousel-fade .carousel-inner > .carousel-item {
    transition: opacity 2s ease;
  }
  #home #carouselHome .carousel-fade .carousel-inner > .carousel-item.next.left,
#home #carouselHome .carousel-fade .carousel-inner > .carousel-item.prev.right,
#home #carouselHome .carousel-fade .carousel-inner > .carousel-item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
#home .btn-reservar {
  height: 65px;
  background: #D9A521;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 18px;
  font-family: "Raleway";
  transform: translate(0px, 36px);
}
@media (max-width: 1100px) {
  #home .logo-reserva {
    width: 79px;
    height: 79px;
    transform: translate(43px, 20px);
  }
}
#home .form-pesquisa {
  max-width: 1391px;
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  bottom: -97px;
  width: 100%;
  left: 17px;
  right: 0;
  padding: 40px 58px;
  background-color: #25224D;
}
@media (max-width: 1100px) {
  #home .form-pesquisa {
    left: 0;
    padding: 0;
    height: 200px;
    padding-inline: 24px;
    width: calc(100% - 48px);
    background: rgba(37, 34, 77, 0.9294117647);
  }
}
#home .form-pesquisa form {
  margin-top: 3px;
  margin-left: 61px;
}
#home .form-pesquisa form .col {
  margin: 0 4px;
}
#home .form-pesquisa form label,
#home .form-pesquisa form input,
#home .form-pesquisa form select {
  width: 100%;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #170024;
  border: none;
  position: relative;
}
#home .form-pesquisa form input[type=date]::before {
  position: absolute;
  left: 16px;
  content: "";
  width: 23px;
  height: 23px;
  background: url("../img/calendar-form.png") center center no-repeat;
  background-size: cover;
}
#home .form-pesquisa form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../img/arrow-select.png");
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: 50%;
}
#home .form-pesquisa form .select-icons {
  position: relative;
}
#home .form-pesquisa form .select-icons img {
  position: absolute;
  left: 16px;
  top: 20px;
}
#home .form-pesquisa form input,
#home .form-pesquisa form select {
  padding: 21px 13px 21px 55px;
}
#home .form-pesquisa form select option {
  padding: 0;
}
#home .form-pesquisa form input[type=submit] {
  margin-top: 32px;
  padding: 21px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
#home .form-pesquisa form input[type=submit]:hover {
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
#home .form-pesquisa form input[type=date]::-webkit-inner-spin-button,
#home .form-pesquisa form input[type=date]::-webkit-calendar-picker-indicator {
  background: url("../img/arrow-select.png") center center no-repeat;
}
#home .form-pesquisa form label {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 10px;
}
#home .form-pesquisa .reserva-grupo {
  display: inline-block;
  text-decoration: none;
  text-align: right;
  color: #D9A521;
  margin-right: 20px;
}
#home .form-pesquisa .reserva-grupo:hover {
  color: #D9A521;
}
#home .title-section {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 65px;
  color: #997A5D;
  text-align: center;
}
#home .title-section small {
  font-family: "Butler-Bold";
  font-size: 48px;
}
@media (max-width: 1100px) {
  #home .title-section {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #home .title-section small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#home p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}
@media (max-width: 1100px) {
  #home p {
    font-size: 14px;
    line-height: 24px;
  }
}
#home .btns {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 55px 21px;
  padding: 21px 55px;
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  #home .btns {
    font-size: 18px;
  }
}
#home .btns:hover {
  transition: 0.3s ease-in;
  background-color: #D2A771;
}
#home .section1 {
  padding-top: 188px;
  padding-bottom: 115px;
}
@media (max-width: 1100px) {
  #home .section1 {
    padding-bottom: 60px;
    padding-top: 145px;
  }
}
#home .section1 .title-section {
  margin-bottom: 45px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #home .section1 .title-section {
    margin-bottom: 30px;
  }
}
#home .section1 p {
  color: #464646;
  max-width: 1270px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #home .section1 p {
    padding-inline: 12px;
  }
}
#home .section1 .btns {
  margin-top: 44px;
}
@media (max-width: 1100px) {
  #home .section1 .btns {
    width: calc(100% - 24px);
  }
}
#home .section2 {
  padding-top: 111px;
  padding-bottom: 119px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
@media (max-width: 1100px) {
  #home .section2 {
    padding-top: 55px;
    padding-bottom: 45px;
  }
}
#home .section2 .container {
  z-index: 2;
  position: relative;
}
#home .section2 .title-section {
  color: #ffffff;
  margin-bottom: 47px;
}
#home .section2 p {
  max-width: 801px;
  margin: 0 auto;
  color: #ffffff;
}
#home .section2 .btns {
  background-color: #ffffff;
  color: #997A5D;
  margin-top: 75px;
  padding: 21px 38px;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  #home .section2 .btns {
    margin-top: 37px;
  }
}
#home .section2 .btns:hover {
  transition: 0.3s ease-in;
  color: #ffffff;
  background-color: #D2A771;
}
#home .section2 .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#home .section3 {
  padding-top: 94px;
  padding-bottom: 129px;
}
@media (max-width: 1100px) {
  #home .section3 {
    padding-top: 55px;
    padding-bottom: 61px;
  }
}
#home .section3 .title-section {
  text-transform: uppercase;
  margin-bottom: 45px;
}
@media (max-width: 1100px) {
  #home .section3 .title-section {
    margin-bottom: 30px;
  }
}
#home .section3 p {
  font-size: 22px;
  max-width: 913px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #home .section3 p {
    font-size: 14px;
    line-height: 20px;
  }
}
#home .section3 .blocos {
  margin-top: 87px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos {
    margin-top: 0px;
  }
}
#home .section3 .blocos .bloco {
  max-width: 625px;
  transition: all 0.5s ease;
  overflow: hidden;
}
#home .section3 .blocos .bloco:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.0509803922);
  -moz-box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.0509803922);
  box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.0509803922);
  cursor: pointer;
}
#home .section3 .blocos .bloco .imagem-bloco {
  width: 100%;
  height: 399px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos .bloco .imagem-bloco {
    height: auto;
  }
}
#home .section3 .blocos .bloco .imagem-bloco img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .section3 .blocos .bloco .texto {
  padding: 49px 47px;
  -webkit-box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.0509803922);
  -moz-box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.0509803922);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.0509803922);
  height: 440px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos .bloco .texto {
    padding-inline: 12px;
    height: auto;
    padding: 20px 47px;
  }
}
#home .section3 .blocos .bloco .texto h3 {
  font-family: "Butler-Regular";
  font-size: 30px;
  font-weight: 400;
  line-height: 26px;
  color: #997A5D;
  text-align: center;
  margin-bottom: 14px;
  height: 63px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos .bloco .texto h3 {
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 22px;
    height: auto;
  }
}
#home .section3 .blocos .bloco .texto p {
  font-size: 20px;
  color: #707070;
  height: 120px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos .bloco .texto p {
    font-size: 14px;
    line-height: 24px;
    height: auto;
  }
}
#home .section3 .blocos .bloco .texto .btns {
  margin-top: 43px;
  padding: 21px 125px;
}
@media (max-width: 1100px) {
  #home .section3 .blocos .bloco .texto .btns {
    padding: 0;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
  }
}
#home .section4 {
  padding-top: 98px;
  padding-bottom: 0px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #home .section4 {
    padding-top: 45px;
    padding-bottom: 220px;
  }
}
#home .section4 .title-section {
  color: #ffffff;
  margin-bottom: 17px;
}
#home .section4 p {
  max-width: 849px;
  margin: 0 auto;
  color: #ffffff;
}
#home .section4 .atrativos {
  margin-top: 79px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos {
    margin-top: 20px;
  }
}
#home .section4 .atrativos .prev {
  position: absolute;
  left: -66px;
  top: 47%;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos .prev {
    left: 36vw;
    top: 125%;
  }
  #home .section4 .atrativos .prev::after {
    content: "";
    position: absolute;
    width: 63px;
    height: 63px;
    border: 1px solid #D9A521;
    border-radius: 50%;
    top: -19px;
    left: -20px;
  }
}
#home .section4 .atrativos .next {
  position: absolute;
  right: -66px;
  top: 47%;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos .next {
    right: 31vw;
    top: 125%;
  }
  #home .section4 .atrativos .next::after {
    content: "";
    position: absolute;
    width: 63px;
    height: 63px;
    border: 1px solid #D9A521;
    border-radius: 50%;
    top: -19px;
    left: -26px;
  }
}
#home .section4 .atrativos .atrativo {
  max-width: 405px;
  height: 518px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos .atrativo {
    height: 267px;
  }
}
#home .section4 .atrativos .atrativo figure,
#home .section4 .atrativos .atrativo .hover01 {
  height: 100%;
}
#home .section4 .atrativos .atrativo figure img {
  height: 100%;
  object-fit: cover;
}
#home .section4 .atrativos .atrativo .texto-atrativo {
  position: absolute;
  height: 260px;
  bottom: 0px;
  padding: 20px 36px;
  z-index: 2;
}
#home .section4 .atrativos .atrativo .texto-atrativo h3 {
  font-family: "Raleway";
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  height: 55px;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos .atrativo .texto-atrativo h3 {
    margin-bottom: 0;
    height: auto;
    font-size: 17px;
    line-height: 20px;
  }
}
#home .section4 .atrativos .atrativo .texto-atrativo p {
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  max-width: 355px;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #home .section4 .atrativos .atrativo .texto-atrativo p {
    font-size: 14px;
    line-height: 22px;
    max-width: 75vw;
    margin-top: 22.4px;
  }
}
#home .section5 {
  padding-top: 157px;
  padding-bottom: 158px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  margin-top: -51px;
}
@media (max-width: 1100px) {
  #home .section5 {
    padding-top: 67px;
    padding-bottom: 67px;
  }
}
#home .section5 p {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 53px;
}
@media (max-width: 1100px) {
  #home .section5 p {
    font-size: 14px;
    line-height: 24px;
  }
}
#home .section5 h3 {
  font-family: "Butler-Regular";
  font-size: 85px;
  line-height: 80px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  margin-bottom: 44px;
}
@media (max-width: 1100px) {
  #home .section5 h3 {
    font: normal normal normal 40px/45px "Butler-Regular";
  }
}
#home .section5 .btns {
  color: #D2A771;
  background-color: #ffffff;
  padding: 21px 69px;
  transition: 0.3s ease-in;
}
#home .section5 .btns:hover {
  transition: 0.3s ease-in;
  color: #ffffff;
  background-color: #D2A771;
}
#home .section5 .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.banner-interna {
  padding: 180px 0 91px 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-interna .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.banner-interna .heading > div {
  overflow: hidden;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
.banner-interna .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.banner-interna .container {
  padding-left: 20px;
  z-index: 2;
  position: inherit;
}
.banner-interna h1 {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 42px;
}
.banner-interna p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  color: #FFFFFF;
}
.banner-interna .scroll-link {
  display: inline-block;
  margin-top: 80px;
  transition: 0.3s ease-in;
}
.banner-interna .scroll-link:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
.banner-interna .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5803921569);
  z-index: 1;
}

#hotel .conteudo-section {
  padding: 90px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #hotel .conteudo-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
#hotel .conteudo-section .bloco-imagem {
  max-width: 968px;
  max-height: 643px;
}
#hotel .conteudo-section .bloco-texto {
  position: absolute;
  right: 6px;
  width: 53%;
  padding: 94px 123px 131px 121px;
  top: 163px;
  background-color: #FFFFFF;
  z-index: 9;
}
@media (max-width: 1100px) {
  #hotel .conteudo-section .bloco-texto {
    position: relative;
    right: inherit;
    top: inherit;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
#hotel .conteudo-section .bloco-texto .title-interna {
  margin-bottom: 65px;
}
@media (max-width: 1100px) {
  #hotel .conteudo-section .bloco-texto .title-interna {
    margin-bottom: 24px;
    margin-top: 30px;
    font: normal normal bold 22px/25px "Butler-Regular";
  }
}
#hotel .conteudo-section .bloco-texto p {
  max-width: 381px;
}
@media (max-width: 1100px) {
  #hotel .conteudo-section .bloco-texto p {
    font-size: 14px;
    line-height: 24px;
  }
}
#hotel p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #707070;
}
@media (max-width: 1100px) {
  #hotel p {
    font-size: 14px;
    line-height: 20px;
  }
}
#hotel .section1 {
  padding-bottom: 26px;
}
#hotel .section1 .container {
  padding-left: 20px;
}
#hotel .section1 .imagem-section {
  overflow: hidden;
}
#hotel .section1 .imagem-section figure {
  margin: 0;
  height: 100%;
}
#hotel .section2 {
  padding: 172px 0 198px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
@media (max-width: 1100px) {
  #hotel .section2 {
    padding: 40px 0;
  }
}
#hotel .section2 .container {
  position: relative;
  z-index: 2;
}
#hotel .section2 .title-interna2,
#hotel .section2 p {
  color: #ffffff;
  text-align: center;
}
#hotel .section2 .title-interna2 {
  margin-bottom: 51px;
}
#hotel .section2 p {
  max-width: 801px;
  margin: 0 auto;
  letter-spacing: 1px;
}
#hotel .section2 .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#hotel .section3 {
  padding: 98px 0 228px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #hotel .section3 {
    padding: 40px 0;
  }
}
#hotel .section3 .title-interna {
  padding-left: 140px;
  padding-top: 113px;
  margin-bottom: 72px;
}
@media (max-width: 1100px) {
  #hotel .section3 .title-interna {
    padding: 0;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 24px;
  }
}
#hotel .section3 p {
  padding-left: 140px;
  max-width: 511px;
}
@media (max-width: 1100px) {
  #hotel .section3 p {
    font-size: 14px;
    line-height: 20px;
    padding: 0;
  }
}
#hotel .section3 .imagem-section {
  width: 794px;
  height: 673px;
  overflow: hidden;
  margin-left: 50px;
}
#hotel .section3 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #hotel .section3 .imagem-section {
    width: 100%;
    height: 209px;
    margin: 0;
  }
}
#hotel .section2.section4 {
  padding: 179px 0 192px 0;
}
@media (max-width: 1100px) {
  #hotel .section2.section4 {
    padding: 40px 0;
  }
}
#hotel .section2.section4 .title-interna2 {
  margin-left: 46px;
}
@media (max-width: 1100px) {
  #hotel .section2.section4 .title-interna2 {
    margin: 0;
    margin-bottom: 24px;
  }
}
#hotel .section2.section4 p {
  max-width: 801px;
}
@media (max-width: 1100px) {
  #hotel .section2.section4 p {
    font-size: 14px;
    line-height: 20px;
  }
}
#hotel .section5 {
  padding: 91px 0 162px 0;
}
@media (max-width: 1100px) {
  #hotel .section5 {
    padding: 40px 0;
  }
}
#hotel .section5 .title-interna {
  text-align: center;
  text-transform: uppercase;
  font-size: 55px;
  line-height: 55px;
  margin-bottom: 86px;
}
#hotel .section5 .title-interna small {
  font-family: "Butler-Bold";
  font-size: 40px;
  line-height: 55px;
}
@media (max-width: 1100px) {
  #hotel .section5 .title-interna {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
    color: #997A5D;
    margin-bottom: 40px;
  }
  #hotel .section5 .title-interna small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#hotel .section5 .quartos {
  margin-bottom: 63px;
}
#hotel .section5 .quartos .acomodacao {
  width: 100%;
  height: 368px;
  position: relative;
  margin-bottom: 26px;
}
#hotel .section5 .quartos .acomodacao .imagem-section {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#hotel .section5 .quartos .acomodacao .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#hotel .section5 .quartos .acomodacao .btn-interna {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  max-width: 342px;
  margin: 0 auto;
  background-color: #D9A521;
  z-index: 9;
}
#hotel .section5 .quartos .acomodacao .btn-interna:hover {
  background-color: #25224D;
  color: #ffffff;
}
#hotel .section5 .btn-interna.reserva {
  margin: 0 auto;
  display: flex;
  max-width: 300px;
  background-color: #25224D;
  color: #D9A521;
  padding: 21px 35px;
  font-size: 22px;
  font-weight: 600;
}
#hotel .section5 .btn-interna.reserva svg {
  margin-right: 15px;
  transition: 0.3s ease-in;
}
#hotel .section5 .btn-interna.reserva:hover {
  color: #25224D;
  background-color: #D9A521;
}
#hotel .section5 .btn-interna.reserva:hover svg path {
  fill: #25224D;
  transition: 0.3s ease-in;
}
#hotel .section6 {
  position: relative;
  padding: 538px 0 94px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #hotel .section6 {
    padding: 40px 0;
  }
  #hotel .section6 .row-imgs-servicos {
    flex-wrap: nowrap;
  }
}
#hotel .section6 .imagens-servicos {
  position: absolute;
  top: -80px;
}
@media (max-width: 1100px) {
  #hotel .section6 .imagens-servicos {
    position: relative;
    top: inherit;
    width: 100%;
    overflow: scroll;
  }
}
#hotel .section6 .imagens-servicos .imagem-section {
  overflow: hidden;
}
#hotel .section6 .imagens-servicos figure {
  margin: 0;
}
@media (max-width: 1100px) {
  #hotel .section6 .imagens-servicos figure img {
    height: 290px;
    width: 230px;
  }
}
#hotel .section6 .title-interna2 {
  max-width: 850px;
  margin-left: 5px;
  margin-bottom: 90px;
}
@media (max-width: 1100px) {
  #hotel .section6 .title-interna2 {
    max-width: 100%;
    margin: 0;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
#hotel .section6 .lista-servicos {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  #hotel .section6 .lista-servicos {
    gap: 3rem;
    width: 100%;
    overflow: scroll;
  }
}
#hotel .section6 .lista-servicos ul {
  padding-left: 5px;
}
#hotel .section6 .lista-servicos ul li {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  #hotel .section6 .lista-servicos ul li {
    font-size: 14px;
    line-height: 18px;
  }
}
#hotel .section6 .lista-servicos ul li img {
  width: 31px;
  height: 31px;
  object-fit: contain;
}
@media (max-width: 1100px) {
  #hotel .section6 .lista-servicos ul li img {
    width: 18px;
    height: 18px;
  }
}

.banner-interna {
  padding: 180px 0 91px 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-interna .container {
  padding-left: 20px;
  z-index: 2;
  position: inherit;
}
.banner-interna .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.banner-interna .heading > div {
  overflow: hidden;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
.banner-interna .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.banner-interna h1 {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 42px;
}
.banner-interna p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  color: #FFFFFF;
}
.banner-interna .scroll-link {
  display: inline-block;
  margin-top: 80px;
  transition: 0.3s ease-in;
}
.banner-interna .scroll-link:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
.banner-interna .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5803921569);
  z-index: 1;
}

#eventos {
  /**/
}
#eventos p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #707070;
}
#eventos .section1 {
  padding: 93px 0 0 0;
  position: relative;
}
@media (max-width: 1100px) {
  #eventos .section1 {
    padding-top: 32px;
    padding-bottom: 0;
  }
}
#eventos .section1 .title-interna {
  padding-left: 123px;
  margin-top: 70px;
  padding-top: 94px;
  margin-bottom: 68px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #eventos .section1 .title-interna {
    padding: 0;
    margin: 0;
    font: normal normal bold 22px/25px "Butler-Regular";
    font-weight: bold;
    margin-top: 34px;
    margin-bottom: 24px;
  }
}
#eventos .section1 p {
  padding-left: 123px;
  max-width: 670px;
  margin-bottom: 32px;
}
@media (max-width: 1100px) {
  #eventos .section1 p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }
}
#eventos .section1 .imagem-section {
  width: 1280px;
  height: 875px;
  position: absolute;
  left: -114px;
  overflow: hidden;
}
#eventos .section1 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #eventos .section1 .imagem-section {
    width: 100%;
    height: 209px;
    position: relative;
    left: inherit;
  }
}
#eventos .section1 .conteudo-section {
  margin-left: 31px;
  padding: 0;
  position: relative;
  background: #ffffff;
  z-index: 99;
  padding-bottom: 53px;
}
@media (max-width: 1100px) {
  #eventos .section1 .conteudo-section {
    padding: 0;
    margin: 0;
    padding-inline: 12px;
  }
}
#eventos #explore {
  padding: 72px 0 0 0;
}
@media (max-width: 1100px) {
  #eventos #explore {
    padding-top: 20px;
  }
}
#eventos #explore h3 {
  color: #997A5D;
  line-height: 59px;
  margin-bottom: 35px;
}
#eventos #explore h3 small {
  color: #997A5D;
}
@media (max-width: 1100px) {
  #eventos #explore h3 {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #eventos #explore h3 small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#eventos #explore .btn-interna {
  background-color: #997A5D;
  margin-bottom: 102px;
}
#eventos #explore .btn-interna:hover {
  background-color: #25224D;
}
#eventos #explore img {
  height: 385px;
  object-fit: cover;
}
#eventos .section2 {
  padding: 93px 0 128px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #eventos .section2 {
    padding-top: 32px;
    padding-bottom: 100px;
  }
}
#eventos .section2 .title-interna2 {
  color: #997A5D;
  margin-left: 5px;
  margin-bottom: 101px;
}
@media (max-width: 1100px) {
  #eventos .section2 .title-interna2 {
    margin-bottom: 30px;
    margin-left: 0;
    text-align: center;
    font: normal normal bold 22px/25px "Butler-Regular";
    font-weight: bold;
  }
}
#eventos .section2 .facilidades .prev {
  position: absolute;
  top: 96px;
  right: 201px;
  cursor: pointer;
}
@media (max-width: 1100px) {
  #eventos .section2 .facilidades .prev {
    top: inherit;
    right: 53vw;
    cursor: pointer;
    bottom: 35px;
  }
}
#eventos .section2 .facilidades .next {
  position: absolute;
  top: 96px;
  right: 121px;
  cursor: pointer;
}
@media (max-width: 1100px) {
  #eventos .section2 .facilidades .next {
    right: 33vw;
    cursor: pointer;
    top: inherit;
    bottom: 35px;
  }
}
#eventos .section2 .facilidades .row,
#eventos .section2 .facilidades .col-lg-6 {
  padding: 0;
  margin: 25px 0;
}
@media (max-width: 1100px) {
  #eventos .section2 .facilidades .row,
#eventos .section2 .facilidades .col-lg-6 {
    margin: 0;
  }
}
#eventos .section2 .facilidades .item {
  max-width: 593px;
  height: 440px;
  padding: 73px 35px 28px 35px;
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.0509803922);
  -moz-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.0509803922);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.0509803922);
}
@media (max-width: 1100px) {
  #eventos .section2 .facilidades .item {
    max-width: 100%;
    height: auto;
    padding-top: 30px;
  }
}
#eventos .section2 .facilidades .item ul li {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #707070;
  display: flex;
  gap: 29px;
  padding-top: 0;
  margin-bottom: 26px;
}
@media (max-width: 1100px) {
  #eventos .section2 .facilidades .item ul li {
    font-size: 14px;
    line-height: 19px;
  }
}
#eventos .section2 .facilidades .item ul li img {
  width: 31px;
}
#eventos .section2 .facilidades .slick-slide {
  height: auto !important;
}
#eventos .section3 {
  padding: 115px 0 170px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #eventos .section3 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
#eventos .section3 .title-interna2 {
  text-align: center;
  margin-bottom: 95px;
}
@media (max-width: 1100px) {
  #eventos .section3 .title-interna2 {
    margin-bottom: 0;
  }
}
#eventos .section3 .planta-baixa {
  margin-top: 97px;
}
#eventos .section3 .planta {
  margin-top: 170px;
}
#eventos .section3 .box_plant img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
#eventos .section3 .f-panzoom {
  background-color: transparent !important;
}
#eventos .section3 .mobile-title-planta {
  font: normal normal bold 17px/55px "Butler-Regular";
  color: #fff;
  background: #D1A670;
  height: 60px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 auto;
  margin-top: 54px;
  position: relative;
  margin-bottom: 25px;
  width: calc(100% - 48px);
}
#eventos .section3 .mobile-title-planta::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: -23px;
  left: 0;
}
#eventos .section3 .mobile-planta-baixa {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 40px;
  width: 100%;
  padding-inline: 24px;
  column-gap: 1rem;
}
#eventos .section3 .item-plant-baixa {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
#eventos .section3 .item-plant-baixa:nth-child(odd)::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 75%;
  background: #fff;
  top: 25%;
  right: -8px;
}
#eventos .section3 .item-plant-baixa img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  margin-left: 18px;
}
#eventos .section3 .item-plant-baixa .title {
  font: normal normal bold 17px/55px "Butler-Regular";
  color: #fff;
  font-weight: bold;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  background: rgba(209, 165, 112, 0.231372549) 0% 0% no-repeat padding-box;
}
#eventos .section3 .item-plant-baixa .text {
  font-family: "Raleway";
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
#eventos .section3 .diferencial-mobile {
  padding-inline: 24px;
  margin-top: 40px;
}
#eventos .section3 .diferencial-mobile .title {
  font: normal normal bold 17px/55px "Butler-Regular";
  height: 45px;
  padding-left: 19px;
  background: rgba(209, 165, 112, 0.231372549) 0% 0% no-repeat padding-box;
  color: #fff;
  font-weight: bold;
}
#eventos .section3 .diferencial-mobile p {
  font-size: 14px;
  font-family: "Raleway";
  color: #fff;
  margin: 0;
}
#eventos .section3 .diferencial-mobile p span {
  color: #D1A670;
}
#eventos #img-zoomer-box {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 10px;
}
#eventos #img-1 {
  width: 100%;
  height: auto;
}
#eventos #img-zoomer-box:hover,
#eventos #img-zoomer-box:active {
  cursor: zoom-in;
  display: block;
}
#eventos #img-zoomer-box:hover #img-2,
#eventos #img-zoomer-box:active #img-2 {
  opacity: 1;
}
#eventos #img-2 {
  width: 340px;
  height: 340px;
  /*background: url('https://homologacao.weedev.com.br/projetos/2023/boulevard-convention/public/userfiles/imagens/event/boulevard-mapa-eventos_png-1748px-726px-2167x654.webp') no-repeat #FFF;*/
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  position: absolute;
  opacity: 0;
  border: 4px solid whitesmoke;
  z-index: 99;
  display: block;
  transition: opacity 0.2s;
  transform: scale(1.5);
}
#eventos .wpp-float {
  position: fixed;
  bottom: 30px;
  right: 30px;
  max-width: 70px;
  z-index: 9999;
}

#pagePolitic .content-politic {
  padding-top: 75px;
  padding-bottom: 140px;
}
@media (max-width: 1100px) {
  #pagePolitic .content-politic {
    padding-inline: 12px;
    padding-top: 40px;
    padding-bottom: 120px;
  }
}
#pagePolitic .content-politic .title {
  text-align: left;
  font: normal normal bold 40px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #pagePolitic .content-politic .title {
    font: normal normal bold 30px/35px "Butler-Regular";
  }
}
#pagePolitic .content-politic .text {
  text-align: left;
  font: normal normal bold 20px Raleway;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 63px;
}
@media (max-width: 1100px) {
  #pagePolitic .content-politic .text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 35px;
  }
}
@media (max-width: 1100px) {
  #pagePolitic .content-politic .text ul {
    padding-left: 0;
  }
}
#pagePolitic .content-politic li {
  color: inherit;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
}
#pagePolitic .content-politic li::before {
  content: "//";
  position: absolute;
  width: 30px;
  height: 30px;
  color: #D2A771;
  font-weight: 700;
  left: 0px;
}
#pagePolitic .content-white {
  background: #fff;
}
#pagePolitic .content-brow {
  background: #EDEDED;
}

.banner-interna {
  padding: 180px 0 91px 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-interna .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.banner-interna .heading > div {
  overflow: hidden;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
.banner-interna .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.banner-interna .container {
  padding-left: 20px;
  z-index: 2;
  position: inherit;
}
.banner-interna h1 {
  font-family: "Bodoni Moda";
  font-weight: 700;
  font-size: 66px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 42px;
}
@media (max-width: 1100px) {
  .banner-interna h1 {
    font: normal normal bold 30px/35px "Butler-Regular";
  }
}
.banner-interna p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  color: #FFFFFF;
}
@media (max-width: 1100px) {
  .banner-interna p {
    font-size: 14px;
    line-height: 20px;
  }
}
.banner-interna .scroll-link {
  display: inline-block;
  margin-top: 80px;
  transition: 0.3s ease-in;
}
.banner-interna .scroll-link:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
.banner-interna .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5803921569);
  z-index: 1;
}

.content-image {
  display: flex;
  width: 100%;
  height: 627px;
  overflow: hidden;
  position: relative;
}

#lugar .conteudo-section {
  padding: 90px 0;
  position: relative;
}
#lugar .conteudo-section .bloco-imagem {
  max-width: 968px;
  max-height: 643px;
}
#lugar .conteudo-section .bloco-texto {
  position: absolute;
  right: 6px;
  width: 53%;
  padding: 94px 123px 131px 121px;
  top: 163px;
  background-color: #FFFFFF;
}
#lugar .conteudo-section .bloco-texto .title-interna {
  margin-bottom: 65px;
}
#lugar .conteudo-section .bloco-texto p {
  max-width: 381px;
}
#lugar p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #707070;
}
@media (max-width: 1100px) {
  #lugar p {
    font-size: 14px;
    line-height: 24px;
  }
}
#lugar .section1 {
  padding: 93px 0 98px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #lugar .section1 {
    padding-top: 32px;
    padding-bottom: 42px;
    padding-inline: 12px;
  }
}
#lugar .section1 .title-interna {
  padding-left: 123px;
  margin-top: 70px;
  padding-top: 94px;
  margin-bottom: 68px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #lugar .section1 .title-interna {
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
    margin-top: 33px;
  }
}
#lugar .section1 p {
  padding-left: 123px;
  max-width: 540px;
  margin-bottom: 32px;
}
@media (max-width: 1100px) {
  #lugar .section1 p {
    margin: 0;
    padding: 0;
  }
}
#lugar .section1 .imagem-section {
  width: 1280px;
  height: 770px;
  position: absolute;
  left: -114px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #lugar .section1 .imagem-section {
    position: relative;
    width: 100%;
    height: 207px;
    left: inherit;
  }
}
#lugar .section1 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#lugar .section1 .conteudo-section {
  margin-left: 31px;
  padding: 0;
  position: relative;
  background: #ffffff;
  z-index: 99;
  padding-bottom: 53px;
}
@media (max-width: 1100px) {
  #lugar .section1 .conteudo-section {
    padding-bottom: 0;
    margin-left: 0;
  }
}
#lugar .section2 {
  padding: 128px 0 88px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}
@media (max-width: 1100px) {
  #lugar .section2 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
#lugar .section2 .container {
  position: relative;
  z-index: 2;
}
#lugar .section2 .title-interna2,
#lugar .section2 p {
  color: #ffffff;
  text-align: center;
}
#lugar .section2 .title-interna2 {
  margin-bottom: 51px;
}
#lugar .section2 p {
  max-width: 801px;
  margin: 0 auto;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  #lugar .section2 p {
    margin-bottom: 0;
  }
}
#lugar .section2 .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#lugar .section3 {
  padding: 119px 0 9px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #lugar .section3 {
    padding-top: 34px;
    padding-bottom: 0;
    padding-inline: 12px;
  }
}
#lugar .section3 .title-interna {
  padding-left: 109px;
  margin-top: 15px;
  padding-top: 67px;
  margin-bottom: 55px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #lugar .section3 .title-interna {
    padding: 0;
    margin: 0;
    margin-top: 34px;
    margin-bottom: 24px;
  }
}
#lugar .section3 p {
  padding-left: 112px;
  max-width: 556px;
  margin-bottom: 32px;
}
@media (max-width: 1100px) {
  #lugar .section3 p {
    padding: 0;
    margin: 0;
  }
}
#lugar .section3 .imagem-section {
  width: 794px;
  height: 671px;
  position: absolute;
  right: 376px;
  overflow: hidden;
}
@media (max-width: 1600px) {
  #lugar .section3 .imagem-section {
    width: 794px;
    height: 671px;
    right: 100px;
  }
}
@media (max-width: 1100px) {
  #lugar .section3 .imagem-section {
    position: relative;
    left: inherit;
    width: 100%;
    height: 207px;
    right: inherit;
  }
}
#lugar .section3 .imagem-section figure {
  height: 100%;
  margin: 0;
}
#lugar .section3 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#lugar .section3 .conteudo-section {
  margin-left: 31px;
  padding: 0;
  position: relative;
  background: #ffffff;
  z-index: 99;
  padding-bottom: 1px;
  margin-top: 44px;
  max-width: 765px;
}
@media (max-width: 1100px) {
  #lugar .section3 .conteudo-section {
    padding: 0;
    margin: 0;
  }
}
#lugar #explore h3 {
  line-height: 62px;
  color: #997A5D;
  margin-bottom: 39px;
}
#lugar #explore h3 small {
  line-height: 62px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #lugar #explore h3 {
    font-size: 22px;
    line-height: 27px;
  }
  #lugar #explore h3 small {
    font-size: 17px;
    line-height: 27px;
  }
}
#lugar #explore .btn-interna {
  background-color: #997A5D;
}
#lugar #explore .btn-interna:hover {
  background-color: #25224D;
}

#contact .mapa {
  width: 100%;
  height: 688px;
}
@media (max-width: 1100px) {
  #contact .mapa {
    height: 330px;
  }
}
#contact .form-content {
  padding-top: 45px;
  position: relative;
  background: #EDEDED;
}
#contact .form-content::after {
  content: "";
  position: absolute;
  top: -60px;
  right: 0;
  width: calc(40% + 77px);
  height: calc(100% + 60px);
  background: #fff;
}
@media (max-width: 1100px) {
  #contact .form-content::after {
    display: none;
  }
}
#contact .form-content .info-box {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
@media (max-width: 1100px) {
  #contact .form-content .info-box {
    padding-inline: 12px;
  }
}
#contact .form-content .info-box .info {
  display: inline-grid;
}
#contact .form-content .info-box .info p {
  margin: 0;
}
#contact .form-content .info-box .info * {
  text-align: left;
  font: normal normal 600 18px/25px Raleway;
  letter-spacing: 0px;
  color: #464646;
  text-decoration: none;
}
@media (max-width: 1100px) {
  #contact .form-content .info-box .info * {
    font: normal normal 600 13px/18px Raleway;
  }
}
#contact .form-content .box-content {
  display: flex;
  gap: 27px;
  padding-left: 37px;
  height: 134px;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1019607843);
  border-left: 8px solid #D2A771;
  width: 110%;
}
@media (max-width: 1300px) {
  #contact .form-content .box-content {
    width: 125%;
    height: 85px;
    padding-left: 22px;
  }
}
#contact .form-content .title {
  text-align: left;
  font: normal normal bold 40px/40px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
  margin-bottom: 50px;
}
@media (max-width: 1100px) {
  #contact .form-content .title {
    font-size: 22px;
    line-height: 25px;
    margin-top: 41px;
    padding-inline: 12px;
    margin-bottom: 27px;
  }
}
#contact .form-content #ct-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 30px;
  column-gap: 7px;
  row-gap: 7px;
}
@media (max-width: 1100px) {
  #contact .form-content #ct-form {
    display: flex;
    flex-direction: column;
    padding-inline: 12px;
  }
}
#contact .form-content #ct-form .message-area {
  grid-column: span 2;
}
#contact .form-content #ct-form .input-group input,
#contact .form-content #ct-form .input-group select {
  width: 100%;
  height: 69px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #D2A771;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-inline: 31px;
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#contact .form-content #ct-form .input-group input::placeholder,
#contact .form-content #ct-form .input-group select::placeholder {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#contact .form-content #ct-form .input-group select {
  background-image: url("../img/arrow-select.png");
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: 50%;
}
#contact .form-content #ct-form .input-group textarea {
  width: 100%;
  height: 175px;
  padding-inline: 31px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #D2A771;
  padding-top: 25px;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#contact .form-content #ct-form .input-group textarea::placeholder {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#contact .form-content #ct-form .terms-group {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 1rem;
  margin-top: 20px;
}
#contact .form-content #ct-form .btn-send {
  width: 202px;
  height: 62px;
  background: #997A5D 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in;
  margin-top: 18px;
  margin-bottom: 138px;
}
@media (max-width: 1100px) {
  #contact .form-content #ct-form .btn-send {
    width: 100%;
    margin-bottom: 46px;
  }
}
#contact .form-content #ct-form .btn-send:hover {
  background-color: #D2A771;
}

.banner-interna {
  padding: 180px 0 91px 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  .banner-interna {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.banner-interna .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.banner-interna .heading > div {
  overflow: hidden;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.banner-interna div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
.banner-interna .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

h1 {
  font-family: "Bodoni Moda";
  font-weight: 700;
  font-size: 66px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 42px;
}
@media (max-width: 1100px) {
  h1 {
    font-size: 30px;
    line-height: 35px;
  }
}

p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  color: #FFFFFF;
}
@media (max-width: 1100px) {
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.scroll-link {
  display: inline-block;
  margin-top: 80px;
  transition: 0.3s ease-in;
}
.scroll-link:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  .scroll-link {
    margin-top: 34px;
  }
}
.scroll-link .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  z-index: 1;
}

.content-image {
  display: flex;
  width: 100%;
  height: 627px;
  overflow: hidden;
  position: relative;
}

#pessoas .conteudo-section {
  padding: 90px 0;
  position: relative;
}
#pessoas .conteudo-section .bloco-imagem {
  max-width: 968px;
  max-height: 643px;
}
#pessoas .conteudo-section .bloco-texto {
  position: absolute;
  right: 6px;
  width: 53%;
  padding: 94px 123px 131px 121px;
  top: 163px;
  background-color: #FFFFFF;
}
#pessoas .conteudo-section .bloco-texto .title-interna {
  margin-bottom: 65px;
}
#pessoas .conteudo-section .bloco-texto p {
  max-width: 381px;
}
#pessoas p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #707070;
}
#pessoas .section1 {
  padding: 93px 0 200px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #pessoas .section1 {
    padding: 30px 0 50px 0;
  }
}
#pessoas .section1 .title-interna {
  padding-left: 123px;
  margin-top: 70px;
  padding-top: 94px;
  margin-bottom: 68px;
  color: #997A5D;
  line-height: 48px;
}
@media (max-width: 1100px) {
  #pessoas .section1 .title-interna {
    margin: 0;
    padding: 0;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 24px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
  }
}
#pessoas .section1 p {
  padding-left: 123px;
  max-width: 100%;
  margin-bottom: 32px;
}
@media (max-width: 1100px) {
  #pessoas .section1 p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
}
#pessoas .section1 .imagem-section {
  width: 968px;
  height: 643px;
  position: absolute;
  left: 330px;
  overflow: hidden;
}
@media (max-width: 1600px) {
  #pessoas .section1 .imagem-section {
    left: 100px;
  }
}
@media (max-width: 1100px) {
  #pessoas .section1 .imagem-section {
    position: relative;
    width: 100%;
    height: 209px;
    object-fit: cover;
    left: 0;
  }
}
#pessoas .section1 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#pessoas .section1 .conteudo-section {
  margin-left: 31px;
  padding: 0;
  position: relative;
  background: #ffffff;
  z-index: 99;
  padding-bottom: 53px;
}
@media (max-width: 1100px) {
  #pessoas .section1 .conteudo-section {
    padding: 0;
    margin-left: 0;
    padding-inline: 12px;
    margin-top: 33px;
  }
}
#pessoas .section2 {
  padding: 128px 0 140px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}
@media (max-width: 1100px) {
  #pessoas .section2 {
    padding: 37px 0 37px 0;
  }
}
#pessoas .section2 .container {
  position: relative;
  z-index: 2;
}
#pessoas .section2 .title-interna2,
#pessoas .section2 h3,
#pessoas .section2 p {
  color: #ffffff;
  text-align: center;
}
#pessoas .section2 .title-interna2 {
  margin-bottom: 51px;
}
@media (max-width: 1100px) {
  #pessoas .section2 .title-interna2 {
    margin-bottom: 0;
  }
}
#pessoas .section2 h3 {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 48px;
}
@media (max-width: 1100px) {
  #pessoas .section2 h3 {
    font: normal normal bold 22px/24px "Butler-Regular";
  }
}
#pessoas .section2 p {
  max-width: 900px;
  margin: 0 auto;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  #pessoas .section2 p {
    font-size: 14px;
    line-height: 24px;
  }
}
#pessoas .section2 .btn-interna {
  padding: 21px 120px;
  margin-top: 45px;
}
@media (max-width: 1100px) {
  #pessoas .section2 .btn-interna {
    margin-top: 0;
  }
}
#pessoas .section2 .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#pessoas .section3 {
  padding: 119px 0 9px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #pessoas .section3 {
    padding: 30px 0 50px 0;
  }
}
#pessoas .section3 .title-interna {
  padding-left: 109px;
  margin-top: 15px;
  padding-top: 67px;
  margin-bottom: 63px;
  color: #997A5D;
  max-width: 618px;
  line-height: 48px;
}
@media (max-width: 1100px) {
  #pessoas .section3 .title-interna {
    line-height: 25px;
    margin: 0;
    padding: 0;
  }
}
#pessoas .section3 p {
  padding-left: 112px;
  max-width: 650px;
  margin-bottom: 32px;
}
@media (max-width: 1100px) {
  #pessoas .section3 p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }
}
#pessoas .section3 p strong {
  color: #997A5D;
}
#pessoas .section3 .btn-interna {
  margin-left: 109px;
  margin-top: 28px;
}
@media (max-width: 1100px) {
  #pessoas .section3 .btn-interna {
    margin-left: 0;
    width: 100%;
  }
}
#pessoas .section3 .imagem-section {
  width: 794px;
  height: 671px;
  position: absolute;
  top: 120px;
  right: 376px;
  overflow: hidden;
}
@media (max-width: 1600px) {
  #pessoas .section3 .imagem-section {
    width: 794px;
    height: 671px;
    right: 100px;
  }
}
@media (max-width: 1100px) {
  #pessoas .section3 .imagem-section {
    position: relative;
    width: 100%;
    height: 209px;
    right: 0;
    top: 0;
  }
}
#pessoas .section3 .imagem-section figure {
  height: 100%;
  margin: 0;
}
#pessoas .section3 .imagem-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#pessoas .section3 .conteudo-section {
  margin-left: 31px;
  padding: 0;
  position: relative;
  background: #ffffff;
  z-index: 99;
  padding-bottom: 1px;
  margin-top: 44px;
  max-width: 765px;
  padding-bottom: 150px;
}
@media (max-width: 1100px) {
  #pessoas .section3 .conteudo-section {
    padding: 0;
    margin: 0;
    padding-inline: 12px;
    margin-top: 24px;
  }
}
#pessoas .section4 {
  padding: 75px 0 86px 0;
  background-color: #D2A771;
  text-align: center;
}
@media (max-width: 1100px) {
  #pessoas .section4 {
    padding: 40px 0 50px 0;
  }
}
#pessoas .section4 .title-interna2 {
  font-size: 54px;
  margin-bottom: 39px;
}
@media (max-width: 1100px) {
  #pessoas .section4 .title-interna2 {
    font: normal normal bold 22px/24px "Butler-Regular";
    margin-bottom: 26px;
  }
}
#pessoas .section4 p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
}
@media (max-width: 1100px) {
  #pessoas .section4 p {
    font-size: 14px;
    line-height: 24px;
  }
}
#pessoas .section4 .redes-sociais {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 1100px) {
  #pessoas .section4 .redes-sociais {
    margin-top: 50px;
  }
}
#pessoas .section4 .redes-sociais a {
  display: inline-block;
  text-decoration: none;
}
#pessoas .section4 .redes-sociais a.redes:hover svg path,
#pessoas .section4 .redes-sociais a.redes:hover svg ellipse {
  fill: #997A5D;
  transition: 0.3s ease-in;
}
#pessoas .section4 .redes-sociais a.youtube:hover svg path {
  stroke: #997A5D !important;
  transition: 0.3s ease-in;
}

#investidor .mapa {
  width: 100%;
  height: 688px;
}
@media (max-width: 1100px) {
  #investidor .mapa {
    height: 330px;
  }
}
#investidor .form-content {
  padding-top: 45px;
  position: relative;
  background: #EDEDED;
}
@media (max-width: 1100px) {
  #investidor .form-content {
    background: #fff;
  }
}
#investidor .form-content::after {
  content: "";
  position: absolute;
  top: -60px;
  right: 0;
  width: 41%;
  height: calc(100% + 60px);
  background: #fff;
}
@media (max-width: 1100px) {
  #investidor .form-content::after {
    display: none;
  }
}
@media (min-width: 1700px) {
  #investidor .form-content::after {
    width: 43%;
  }
}
#investidor .form-content .info-box {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
@media (max-width: 1100px) {
  #investidor .form-content .info-box {
    padding-inline: 12px;
    margin-bottom: 50px;
  }
}
#investidor .form-content .info-box .info {
  display: inline-grid;
}
#investidor .form-content .info-box .info p {
  margin: 0;
}
#investidor .form-content .info-box .info * {
  text-align: left;
  font: normal normal 600 18px/25px Raleway;
  letter-spacing: 0px;
  color: #464646;
  text-decoration: none;
}
@media (max-width: 1100px) {
  #investidor .form-content .info-box .info * {
    font: normal normal 600 13px/18px Raleway;
  }
}
#investidor .form-content .box-content {
  display: flex;
  gap: 29px;
  padding-left: 37px;
  height: 134px;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1019607843);
  border-left: 8px solid #D2A771;
  width: 105%;
}
@media (max-width: 1400px) {
  #investidor .form-content .box-content {
    width: 130%;
  }
}
@media (max-width: 1100px) {
  #investidor .form-content .box-content {
    width: 100%;
    height: 85px;
    padding-left: 22px;
  }
}
#investidor .form-content .title {
  text-align: left;
  font: normal normal bold 40px/40px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
  margin-bottom: 50px;
}
@media (max-width: 1100px) {
  #investidor .form-content .title {
    font-size: 22px;
    line-height: 25px;
    margin-top: 41px;
    padding-inline: 12px;
    margin-bottom: 27px;
  }
}
#investidor .form-content #ct-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 30px;
  column-gap: 7px;
  row-gap: 7px;
}
@media (max-width: 1100px) {
  #investidor .form-content #ct-form {
    display: flex;
    flex-direction: column;
    padding-inline: 12px;
  }
}
#investidor .form-content #ct-form .message-area {
  grid-column: span 2;
}
#investidor .form-content #ct-form .input-group input, #investidor .form-content #ct-form .input-group select {
  width: 100%;
  height: 69px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #D2A771;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-inline: 31px;
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#investidor .form-content #ct-form .input-group input::placeholder, #investidor .form-content #ct-form .input-group select::placeholder {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#investidor .form-content #ct-form .input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../img/arrow-select.png");
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: 50%;
}
#investidor .form-content #ct-form .input-group textarea {
  width: 100%;
  height: 175px;
  padding-inline: 31px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #D2A771;
  padding-top: 25px;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#investidor .form-content #ct-form .input-group textarea::placeholder {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
  color: #464646;
}
#investidor .form-content #ct-form .terms-group {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 1rem;
  margin-top: 20px;
}
#investidor .form-content #ct-form .btn-send {
  width: 202px;
  height: 62px;
  background: #997A5D 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in;
  margin-top: 18px;
  margin-bottom: 138px;
}
@media (max-width: 1100px) {
  #investidor .form-content #ct-form .btn-send {
    width: 100%;
    margin-bottom: 46px;
  }
}
#investidor .form-content #ct-form .btn-send:hover {
  background-color: #D2A771;
}

#reservas .banner-interna {
  padding: 180px 0 434px 0;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #reservas .banner-interna {
    padding: 81px 0 156px 0;
  }
}
#reservas .banner-interna .container {
  padding-left: 20px;
  z-index: 2;
  position: inherit;
}
#reservas .banner-interna .btn-atrativo {
  display: none;
}
@media (max-width: 1100px) {
  #reservas .banner-interna .btn-atrativo {
    display: block;
  }
}
#reservas .banner-interna .heading {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
#reservas .banner-interna .heading > div {
  overflow: hidden;
}
#reservas .banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
#reservas .banner-interna div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
#reservas .banner-interna div.heading > div {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
#reservas .banner-interna div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}
#reservas .banner-interna .slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
#reservas .banner-interna h1 {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 102px;
}
@media (max-width: 1100px) {
  #reservas .banner-interna h1 {
    font: normal normal bold 30px/35px "Butler-Regular";
    margin-bottom: 50px;
  }
}
#reservas .banner-interna p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  color: #FFFFFF;
  text-align: left;
}
@media (max-width: 1100px) {
  #reservas .banner-interna p {
    font-size: 14px;
    line-height: 20px;
    max-width: 75%;
    margin-bottom: 30px;
  }
}
#reservas .banner-interna .scroll-link {
  display: inline-block;
  margin-top: 80px;
  transition: 0.3s ease-in;
}
#reservas .banner-interna .scroll-link:hover svg path {
  fill: #d9a521;
  transition: 0.3s ease-in;
}
#reservas .banner-interna .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5803921569);
  z-index: 1;
}
#reservas .btn-reservar {
  height: 65px;
  background: #D9A521;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 18px;
  font-family: "Raleway";
  transform: translate(0px, 36px);
}
@media (max-width: 1100px) {
  #reservas .logo-reserva {
    width: 79px;
    height: 79px;
    transform: translate(43px, 20px);
  }
}
#reservas .form-pesquisa {
  max-width: 1391px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  margin-top: -133px;
  width: 100%;
  padding: 40px 58px;
  background-color: #25224D;
}
@media (max-width: 1100px) {
  #reservas .form-pesquisa {
    left: 0;
    padding: 0;
    height: 200px;
    padding-inline: 24px;
    width: calc(100% - 48px);
    background: rgba(37, 34, 77, 0.9294117647);
  }
}
#reservas .form-pesquisa form {
  margin-top: 3px;
  margin-left: 61px;
}
#reservas .form-pesquisa form .col {
  margin: 0 4px;
}
#reservas .form-pesquisa form label,
#reservas .form-pesquisa form input,
#reservas .form-pesquisa form select {
  width: 100%;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #170024;
  border: none;
  position: relative;
}
#reservas .form-pesquisa form input[type=date]::before {
  position: absolute;
  left: 16px;
  content: "";
  width: 23px;
  height: 23px;
  background: url("../img/calendar-form.png") center center no-repeat;
  background-size: cover;
}
#reservas .form-pesquisa form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../img/arrow-select.png");
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: 50%;
}
#reservas .form-pesquisa form .select-icons {
  position: relative;
}
#reservas .form-pesquisa form .select-icons img {
  position: absolute;
  left: 16px;
  top: 20px;
}
#reservas .form-pesquisa form input,
#reservas .form-pesquisa form select {
  padding: 21px 23px 21px 55px;
}
#reservas .form-pesquisa form select option {
  padding: 0;
}
#reservas .form-pesquisa form input[type=submit] {
  margin-top: 32px;
  padding: 21px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
#reservas .form-pesquisa form input[type=submit]:hover {
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
#reservas .form-pesquisa form input[type=date]::-webkit-inner-spin-button,
#reservas .form-pesquisa form input[type=date]::-webkit-calendar-picker-indicator {
  background: url("../img/arrow-select.png") center center no-repeat;
}
#reservas .form-pesquisa form label {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 10px;
}
#reservas .form-pesquisa .reserva-grupo {
  display: inline-block;
  text-decoration: none;
  text-align: right;
  color: #D9A521;
  margin-right: 20px;
}
#reservas .form-pesquisa .reserva-grupo:hover {
  color: #D9A521;
}
#reservas .title-reserva {
  font-family: "Butler-Bold";
  font-size: 55px;
  line-height: 55px;
  color: #D9A521;
  text-align: center;
}
#reservas .section1 {
  padding: 93px 0 70px 0;
}
@media (max-width: 1100px) {
  #reservas .section1 {
    padding-top: 32px;
  }
}
#reservas .section1 .title-reserva {
  margin-bottom: 65px;
}
@media (max-width: 1100px) {
  #reservas .section1 .title-reserva {
    display: none;
  }
}
#reservas .section1 .blocos {
  margin-left: 22px;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos {
    margin-left: 0;
    padding-inline: 12px;
  }
}
#reservas .section1 .blocos .bloco-reserva {
  max-width: 406px;
  margin-bottom: 18px;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva {
  width: 100%;
  height: 268px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .imagem-reserva {
    height: 100%;
  }
  #reservas .section1 .blocos .bloco-reserva .imagem-reserva figure {
    margin: 0;
  }
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .tag {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 5px 33px;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .tag a {
  display: inline-block;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: #FFFFFF;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .tag:hover {
  transition: 0.3s ease-in;
  background-color: #997A5D;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .tag:hover a {
  color: #ffffff;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 48px;
  width: 100%;
  padding: 14px 30px;
  background: #04040a;
  opacity: 0.8;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa {
    padding-left: 22px;
    gap: 27px;
  }
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa div {
  display: flex;
  align-items: center;
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa p {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa p {
    font: normal normal 600 14px/16px Raleway;
  }
}
#reservas .section1 .blocos .bloco-reserva .imagem-reserva .faixa svg {
  margin-right: 8px;
}
#reservas .section1 .blocos .bloco-reserva .conteudo {
  padding: 26px 33px;
  color: #FFFFFF;
  background-color: #25224D;
}
#reservas .section1 .blocos .bloco-reserva .conteudo .titulo-bloco {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 20px;
  max-width: 240px;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .conteudo .titulo-bloco {
    font: normal normal 800 17px/20px Raleway;
  }
}
#reservas .section1 .blocos .bloco-reserva .conteudo p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .conteudo p {
    font-size: 14px;
    line-height: 25px;
  }
}
#reservas .section1 .blocos .bloco-reserva .conteudo .valores {
  display: flex;
  align-items: center;
  gap: 24px;
}
#reservas .section1 .blocos .bloco-reserva .conteudo .valores .valor {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .conteudo .valores .valor {
    font-size: 18px;
    line-height: 21px;
  }
}
#reservas .section1 .blocos .bloco-reserva .conteudo .valores .noite {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  opacity: 0.6;
  margin-top: 5px;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .conteudo .valores .noite {
    font-size: 15px;
    line-height: 18px;
  }
}
#reservas .section1 .blocos .bloco-reserva .conteudo .btn-bloco {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 19px 108px;
  margin-top: 30px;
  color: #ffffff;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  #reservas .section1 .blocos .bloco-reserva .conteudo .btn-bloco {
    height: 65px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#reservas .section1 .blocos .bloco-reserva .conteudo .btn-bloco:hover {
  transition: 0.3s ease-in;
  background-color: #997A5D;
  color: #ffffff;
}
#reservas .title-section {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 65px;
  color: #997A5D;
  text-align: center;
}
#reservas .title-section small {
  font-family: "Butler-Bold";
  font-size: 48px;
}
@media (max-width: 1100px) {
  #reservas .title-section {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #reservas .title-section small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#reservas p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}
@media (max-width: 1100px) {
  #reservas p {
    font-size: 14px;
    line-height: 24px;
  }
}
#reservas .btns {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 55px 21px;
  padding: 21px 55px;
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  #reservas .btns {
    font-size: 18px;
  }
}
#reservas .btns:hover {
  transition: 0.3s ease-in;
  background-color: #D2A771;
}
#reservas .section1-home {
  padding-top: 188px;
  padding-bottom: 115px;
}
@media (max-width: 1100px) {
  #reservas .section1-home {
    padding-bottom: 60px;
    padding-top: 145px;
  }
}
#reservas .section1-home .title-section {
  margin-bottom: 45px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #reservas .section1-home .title-section {
    margin-bottom: 30px;
  }
}
#reservas .section1-home p {
  color: #464646;
  max-width: 1270px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 1100px) {
  #reservas .section1-home p {
    padding-inline: 12px;
  }
}
#reservas .section1-home .btns {
  margin-top: 44px;
}
@media (max-width: 1100px) {
  #reservas .section1-home .btns {
    width: calc(100% - 24px);
  }
}

#reservasDetail h1 {
  font-family: "Butler-Bold";
  font-size: 35px;
  line-height: 40px;
  color: #D9A521;
  text-align: left;
}
#reservasDetail .title-reserva {
  font-family: "Butler-Bold";
  font-size: 55px;
  line-height: 55px;
  color: #D9A521;
  text-align: center;
}
#reservasDetail .section1 {
  padding: 65px 0 0 0;
}
#reservasDetail .section1 .voltar {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  color: #707070;
  padding-bottom: 15px;
  border-bottom: solid 1px #707070;
  transition: 0.3s ease-in;
  margin-top: 14px;
}
#reservasDetail .section1 .voltar:hover {
  transition: 0.3s ease-in;
  color: #25224D;
}
#reservasDetail .section1 .reservar {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 22px;
  color: #D9A521;
  background-color: #25224D;
  padding: 15px 26px;
  transition: 0.3s ease-in;
}
#reservasDetail .section1 .reservar svg {
  transform: rotate(90deg);
  margin-right: 9px;
  transition: 0.3s ease-in;
}
#reservasDetail .section1 .reservar:hover {
  transition: 0.3s ease-in;
  color: #25224D;
  background-color: #D9A521;
}
#reservasDetail .section1 .reservar:hover svg path {
  fill: #25224D;
  transition: 0.3s ease-in;
}
#reservasDetail .section1 .galeria {
  margin-top: 37px;
}
#reservasDetail .section1 .galeria .col-lg-7 {
  padding: 0;
}
#reservasDetail .section1 .galeria .card-principal {
  width: 100%;
  height: 466px;
  overflow: hidden;
}
#reservasDetail .section1 .galeria .card-principal a {
  width: 100%;
  height: 100%;
}
#reservasDetail .section1 .galeria .card-principal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#reservasDetail .section1 .galeria .card {
  width: 272px;
  height: 228px;
  margin-bottom: 10px;
  overflow: hidden;
}
#reservasDetail .section1 .galeria .card a {
  width: 100%;
  height: 100%;
}
#reservasDetail .section1 .galeria .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#reservasDetail .section1 .sobre {
  padding: 40px 0 88px 0;
}
#reservasDetail .section1 .sobre h2 {
  font-family: "Butler-Regular";
  font-size: 25px;
  line-height: 26px;
  color: #25224D;
  margin-bottom: 40px;
}
#reservasDetail .section1 .sobre p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #707070;
  max-width: 570px;
}
#reservasDetail .section1 .sobre .diferencial {
  display: flex;
  align-items: center;
  gap: 15px;
  align-items: center;
  margin-bottom: 31px;
}
#reservasDetail .section1 .sobre .diferencial p {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  color: #707070;
  margin-bottom: 0;
}
#reservasDetail .section2 {
  padding: 144px 0 150px 0;
  background-color: #F4F4F4;
}
#reservasDetail .section2 .title-reserva {
  margin-bottom: 76px;
}
#reservasDetail .section2 .blocos {
  margin-left: 22px;
}
#reservasDetail .section2 .blocos .bloco-reserva {
  max-width: 406px;
  margin-bottom: 18px;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva {
  width: 100%;
  height: 268px;
  position: relative;
  overflow: hidden;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .tag {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 5px 33px;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .tag a {
  display: inline-block;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: #FFFFFF;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .tag:hover {
  transition: 0.3s ease-in;
  background-color: #997A5D;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .tag:hover a {
  color: #ffffff;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .faixa {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 48px;
  width: 100%;
  padding: 14px 30px;
  background: #04040a;
  opacity: 0.8;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .faixa div {
  display: flex;
  align-items: center;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .faixa p {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 0;
}
#reservasDetail .section2 .blocos .bloco-reserva .imagem-reserva .faixa svg {
  margin-right: 8px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo {
  padding: 26px 33px;
  color: #FFFFFF;
  background-color: #25224D;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .titulo-bloco {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 20px;
  max-width: 240px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .valores {
  display: flex;
  align-items: center;
  gap: 24px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .valores .valor {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .valores .noite {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  opacity: 0.6;
  margin-top: 5px;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .btn-bloco {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 19px 108px;
  margin-top: 30px;
  color: #ffffff;
  background-color: #D9A521;
  transition: 0.3s ease-in;
}
#reservasDetail .section2 .blocos .bloco-reserva .conteudo .btn-bloco:hover {
  transition: 0.3s ease-in;
  background-color: #997A5D;
  color: #ffffff;
}

#who .banner {
  min-height: 759px;
  padding-top: 177px;
}
@media (max-width: 1100px) {
  #who .banner {
    min-height: auto;
    padding-top: 50px;
    padding-bottom: 45px;
  }
}
#who .banner .content {
  gap: 75px;
}
@media (max-width: 1100px) {
  #who .banner .content {
    gap: 30px;
  }
}
#who .title-section {
  font-family: "Butler-Bold";
  font-size: 66px;
  line-height: 65px;
  color: #997A5D;
  text-align: center;
}
#who .title-section small {
  font-family: "Butler-Bold";
  font-size: 48px;
}
@media (max-width: 1100px) {
  #who .title-section {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #who .title-section small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#who p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
}
#who .btns {
  display: inline-block;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 55px 21px;
  padding: 21px 55px;
  background-color: #997A5D;
  transition: 0.3s ease-in;
}
#who .btns:hover {
  transition: 0.3s ease-in;
  background-color: #D2A771;
}
#who .conteudo-section {
  padding: 90px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #who .conteudo-section {
    padding-top: 30px;
    padding-bottom: 0;
  }
}
#who .conteudo-section .bloco-imagem {
  max-width: 968px;
  max-height: 643px;
}
#who .conteudo-section .bloco-texto {
  position: absolute;
  right: 6px;
  width: 70%;
  padding: 94px 123px 131px 121px;
  top: 163px;
  background-color: #FFFFFF;
  z-index: 9;
  left: 48%;
}
@media (max-width: 1100px) {
  #who .conteudo-section .bloco-texto {
    position: relative;
    right: inherit;
    top: inherit;
    left: inherit;
    padding: 0;
    width: 100%;
  }
}
#who .conteudo-section .bloco-texto .title-interna {
  margin-bottom: 65px;
  color: #997A5D !important;
  line-height: 57px;
}
#who .conteudo-section .bloco-texto .title-interna strong {
  color: #997A5D !important;
}
@media (max-width: 1100px) {
  #who .conteudo-section .bloco-texto .title-interna {
    line-height: 25px;
    font-size: 20px;
    margin-top: 33px;
    margin-bottom: 24px;
  }
}
#who .conteudo-section .bloco-texto p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #707070;
  text-align: left;
}
@media (max-width: 1100px) {
  #who .conteudo-section .bloco-texto p {
    font-size: 14px;
    line-height: 24px;
  }
}
#who .section1 {
  padding-bottom: 26px;
  overflow: hidden;
  padding-bottom: 200px;
}
@media (max-width: 1100px) {
  #who .section1 {
    padding-bottom: 40px;
  }
}
#who .section1 .container {
  padding-left: 20px;
  max-width: 1400px !important;
}
#who .section1 .imagem-section {
  overflow: hidden;
}
#who .section1 .imagem-section figure {
  margin: 0;
  height: 100%;
}
#who .section4 {
  padding-top: 98px;
  padding-bottom: 0px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #who .section4 {
    padding-top: 45px;
    padding-bottom: 220px;
  }
}
#who .section4 .title-section {
  color: #ffffff;
  margin-bottom: 17px;
}
@media (max-width: 1100px) {
  #who .section4 .title-section {
    font: normal normal bold 22px/27px "Butler-Regular";
    font-weight: bold;
  }
  #who .section4 .title-section small {
    font: normal normal bold 17px/27px "Butler-Regular";
    font-weight: bold;
  }
}
#who .section4 p {
  max-width: 849px;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
}
#who .section4 .atrativos {
  margin-top: 79px;
  position: relative;
  z-index: 2;
  transform: translate(0px, 41px);
  z-index: 9999;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos {
    margin-top: 20px;
  }
}
#who .section4 .atrativos .prev {
  position: absolute;
  left: -66px;
  top: 47%;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos .prev {
    left: 36vw;
    top: 125%;
  }
  #who .section4 .atrativos .prev::after {
    content: "";
    position: absolute;
    width: 63px;
    height: 63px;
    border: 1px solid #D9A521;
    border-radius: 50%;
    top: -19px;
    left: -20px;
  }
}
#who .section4 .atrativos .next {
  position: absolute;
  right: -66px;
  top: 47%;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos .next {
    right: 31vw;
    top: 125%;
  }
  #who .section4 .atrativos .next::after {
    content: "";
    position: absolute;
    width: 63px;
    height: 63px;
    border: 1px solid #D9A521;
    border-radius: 50%;
    top: -19px;
    left: -26px;
  }
}
#who .section4 .atrativos .atrativo {
  max-width: 405px;
  height: 518px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos .atrativo {
    height: 267px;
  }
}
#who .section4 .atrativos .atrativo .texto-atrativo {
  position: absolute;
  height: 142px;
  bottom: 95px;
  padding: 20px 36px;
  z-index: 2;
}
#who .section4 .atrativos .atrativo .texto-atrativo h3 {
  font-family: "Raleway";
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  height: 55px;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos .atrativo .texto-atrativo h3 {
    margin-bottom: 0;
    height: auto;
    font-size: 17px;
    line-height: 20px;
  }
}
#who .section4 .atrativos .atrativo .texto-atrativo p {
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  max-width: 355px;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #who .section4 .atrativos .atrativo .texto-atrativo p {
    font-size: 14px;
    line-height: 22px;
    max-width: 75vw;
    margin-top: 22.4px;
  }
}
#who #mapa {
  z-index: 1;
}

#atrativo h1, #atrativo h2, #atrativo h3, #atrativo p, #atrativo img {
  margin: 0;
}
#atrativo .atrativo-load-more {
  height: 65px;
  color: #fff;
  font-size: 22px;
  font-family: "Raleway";
  font-weight: bold;
  max-width: clamp(100%, 300px, 100%);
  background: #997A5D;
  width: clamp(300px, 100%, 300px);
  margin-bottom: 32px;
}
#atrativo .btn-atrativo {
  margin-top: 127px;
  width: fit-content;
}
@media (max-width: 1100px) {
  #atrativo .btn-atrativo {
    margin-top: 40px;
  }
}
#atrativo .banner {
  min-height: 760px;
  padding-top: 177px;
}
@media (max-width: 1100px) {
  #atrativo .banner {
    padding-top: 81px;
    min-height: 418px;
  }
}
#atrativo .lugares {
  padding-top: 106px;
  background: #FBFBFB;
}
@media (max-width: 1100px) {
  #atrativo .lugares {
    padding-top: 30px;
  }
}
#atrativo .lugares .content .title-section {
  text-align: center;
  font: normal normal bold 55px/65px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #atrativo .lugares .content .title-section {
    font: normal normal bold 21px/27px "Butler-Regular";
  }
}
#atrativo .lugares .content .title-section small {
  text-align: center;
  font: normal normal bold 40px/65px "Butler-Regular";
  font-family: "Butler-Bold";
  letter-spacing: 0px;
  color: #997A5D;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #atrativo .lugares .content .title-section small {
    font: normal normal bold 17px/27px "Butler-Regular";
  }
}
#atrativo .lugares .content .text {
  text-align: center;
  letter-spacing: 0px;
  color: #464646;
  margin-top: 50px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 91px;
}
@media (max-width: 1100px) {
  #atrativo .lugares .content .text {
    margin-top: 30px;
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 24px;
  }
}
#atrativo .card-atrativo {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.0509803922);
  margin-bottom: 51px;
}
#atrativo .card-atrativo .wrapper-img {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1.566;
}
@media (max-width: 1100px) {
  #atrativo .card-atrativo .wrapper-img {
    aspect-ratio: 1.755;
  }
}
#atrativo .card-atrativo .wrapper-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(210, 167, 113);
  background: linear-gradient(126deg, rgb(210, 167, 113) 0%, rgb(255, 255, 255) 90%);
  opacity: 0.1;
}
#atrativo .card-atrativo .wrapper-img img {
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}
#atrativo .card-atrativo .wrapper-img:hover img {
  transform: scale(1.1);
}
#atrativo .card-atrativo .body {
  padding-inline: 45px;
  padding-top: 50px;
  padding-bottom: 59px;
  background: #fff;
  min-height: 269px;
}
@media (max-width: 1100px) {
  #atrativo .card-atrativo .body {
    padding-inline: 24px;
    padding-top: 26px;
    padding-bottom: 52px;
    height: auto;
  }
}
#atrativo .card-atrativo .body .title {
  text-align: center;
  font: normal normal normal 30px/26px "Butler-Regular";
  letter-spacing: 0px;
  color: #997A5D;
}
@media (max-width: 1100px) {
  #atrativo .card-atrativo .body .title {
    font: normal normal normal 17px/12px "Butler-Regular";
  }
}
#atrativo .card-atrativo .body .text {
  text-align: center;
  letter-spacing: 0px;
  margin: 0;
  color: #707070;
  margin-top: 15px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}
@media (max-width: 1100px) {
  #atrativo .card-atrativo .body .text {
    font-size: 14px;
    line-height: 24px;
  }
}