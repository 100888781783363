footer {
    padding-top: 76px;
    background-color: #997A5D;

    .parte1 {
        margin-bottom: 29px;
    }

    .logo-footer {
        margin-top: 4px;
        margin-left: 7px;
        margin-bottom: 71px;
    }

    .frase {
        margin-left: 7px;
        font-family: "Raleway";
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        max-width: 270px;
        color: #ffffff;
        display: block;

        @media (max-width: 1100px) {
            display: none;
        }
    }

    h4 {
        font-family: 'Butler-Bold';
        font-size: 17px;
        line-height: 21px;
        color: #D2A771;
        margin-left: 34px;
        margin-bottom: 35px;
        text-align: left;

        @media (max-width: 1100px) {
            margin-left: 0;
            text-align: center;
            font-size: 22px;
        }
    }

    ul {
        padding-left: 0;
        margin-left: 34px;
        text-align: left;

        @media (max-width: 1100px) {
            margin-left: 0;
            margin-bottom: 0;
            text-align: center;
        }

        li {
            margin-bottom: 19px;

            @media (max-width: 1100px) {
                margin-bottom: 26px;
            }

            a {
                text-decoration: none;
                font-family: "Raleway";
                font-weight: 600;
                font-size: 18px;
                line-height: 19px;
                color: #ffffff;
                transition: .3s ease-in;

                @media (max-width: 1100px) {
                    font-size: 16px;
                }

                &:hover {
                    transition: .3s ease-in;
                    color: #D2A771;
                }
            }

            label {
                font-family: "Raleway";
                font-weight: 600;
                font-size: 18px;
                line-height: 19px;
                color: #ffffff;
                transition: .3s ease-in;
                display: flex;
                align-items: center;
                gap: 5px;
                margin: 0;
            }
        }
    }

    .coluna1 {
        .d-flex {
            gap: 26px;

            @media (max-width: 1100px) {
                gap: 0;
            }
        }
    }

    .coluna2 {
        .d-flex {
            gap: 58px;

            @media (max-width: 1100px) {
                gap: 40px;
            }
        }

        div:first-child {
            margin-left: 14px;

            @media (max-width: 1100px) {
                margin-left: 0;
            }
        }

        @media (max-width: 1100px) {
            margin-top: 29px;
        }
    }

    .parte2 {
        margin-bottom: 79px;

        @media (max-width: 1100px) {
            margin-bottom: 50px;
        }
    }

    .btns {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        font-family: "Raleway";
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #997A5D;
        padding: 10px;
        text-decoration: none;
        background-color: #ffffff;
        transition: .3s ease-in;
        width: 100%;
        text-align: center;

        @media (max-width: 1100px) {
            margin-left: 0;
            text-align: center;
            width: 100%;
            height: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-top: 70px;
            width: 100%;
        }

        &:hover {
            background-color: #D2A771;
            color: #ffffff;
            transition: .3s ease-in;

            svg path {
                fill: #fff;
            }
        }
    }

    .redes-sociais {
        display: flex;
        justify-content: flex-end;
        gap: 35px;
        align-items: end;
        height: 100%;

        @media (max-width: 1100px) {
            justify-content: center;
        }

        a.redes:hover svg path,
        a.redes:hover svg ellipse {
            fill: #D2A771;
            transition: .3s ease-in;
        }

        a.youtube:hover svg path {
            stroke: #D2A771 !important;
            transition: .3s ease-in;
        }
    }

    .copy {
        padding-top: 33px;
        padding-bottom: 16px;
        background-color: #937457;

        p {
            font-family: "Raleway";
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            color: #F5EEE5;

            @media (max-width: 1100px) {
                margin: 0;
                margin-top: 19px;
                text-align: center;
            }
        }

        a {
            transition: .3s ease-in;
        }

        a:hover svg path {
            fill: #25224D;
            transition: .3s ease-in;
        }
    }
}
