#atrativo{
    h1, h2, h3, p, img{
        margin: 0;
    }
    .atrativo-load-more{
        height: 65px;
        color: #fff;
        font-size: 22px;
        font-family: 'Raleway';
        font-weight: bold;
        max-width: clamp(100%, 300px, 100%);
        background: #997A5D;
        width: clamp(300px, 100%, 300px);
        margin-bottom: 32px;
    }
    .btn-atrativo{
        margin-top: 127px;
        width: fit-content;
        @media (max-width: 1100px) {
            margin-top: 40px;
        }
    }
    .banner{
        min-height: 760px;
        padding-top: 177px;
        @media (max-width: 1100px) {
            padding-top: 81px;
            min-height: 418px;
        }
    }
    .lugares{
        padding-top: 106px;
        background: #FBFBFB;
        @media (max-width: 1100px) {
            padding-top: 30px;
        }
        .content{
            .title-section{
                text-align: center;
                font: normal normal bold 55px/65px "Butler-Regular";
                letter-spacing: 0px;
                color: #997A5D;
                text-transform: uppercase;
                @media (max-width: 1100px){
                    font: normal normal bold 21px/27px "Butler-Regular";
                }
                small{
                    text-align: center;
                    font: normal normal bold 40px/65px "Butler-Regular";
                    font-family: 'Butler-Bold';
                    letter-spacing: 0px;
                    color: #997A5D;
                    text-transform: uppercase;
                    @media (max-width: 1100px){
                        font: normal normal bold 17px/27px "Butler-Regular";
                    }
                }
            }
            .text{
                text-align: center;
                letter-spacing: 0px;
                color: #464646;
                margin-top: 50px;
                font-family: 'Raleway';
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 91px;
                @media (max-width: 1100px){
                    margin-top: 30px;
                    margin-bottom: 35px;
                    font-size:  14px;
                    line-height:  24px;
                }
            }
        }
    }

    .card-atrativo{
        box-shadow: 0px 20px 30px #0000000D;
        margin-bottom: 51px;
        .wrapper-img{
            position: relative;
            overflow: hidden;
            aspect-ratio: 1.566;
            @media (max-width: 1100px){
                aspect-ratio: 1.755;
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(210,167,113);
                background: linear-gradient(126deg, rgba(210,167,113,1) 0%, rgba(255,255,255,1) 90%);
                opacity: .1;
            }
            img{
                transition: all .3s ease-in-out;
                width: 100%;
                height: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
        }
        .body{
            padding-inline: 45px;
            padding-top: 50px;
            padding-bottom: 59px;
            background: #fff;
            min-height: 269px;
            @media (max-width: 1100px){
                padding-inline: 24px;
                padding-top: 26px;
                padding-bottom: 52px;
                height: auto;
            }
            .title{
                text-align: center;
                font: normal normal normal 30px/26px "Butler-Regular";
                letter-spacing: 0px;
                color: #997A5D;
                @media (max-width: 1100px){
                    font: normal normal normal 17px/12px "Butler-Regular";
                }
            }
            .text{
                text-align: center;
                letter-spacing: 0px;
                margin: 0;
                color: #707070;
                margin-top: 15px;
                font-family: 'Raleway';
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                @media (max-width: 1100px){
                    font-size:  14px;
                    line-height:  24px;
                }
            }
        }
    }
}
