#investidor{
    .mapa{
            width: 100%;
            height: 688px;
            @media (max-width: 1100px) {
                height: 330px;
            }
    }
    .form-content{
        padding-top: 45px;
        position: relative;
        background: #EDEDED;
        @media (max-width: 1100px) {
            background: #fff;
        }
        &::after{
            content: '';
            position: absolute;
            top: -60px;
            right: 0;
            width: 41%;
            height: calc(100% + 60px);
            background: #fff;
            @media (max-width: 1100px) {
                display: none;
            }
            @media(min-width: 1700px){
                width: 43%;
            }
        }
        .info-box{
            display: flex;
            flex-direction: column;
            gap: 11px;
            @media (max-width: 1100px) {
                padding-inline: 12px;
                margin-bottom: 50px;
            }
            .info{
                display: inline-grid;
                p{
                    margin: 0;
                }
                *{
                    text-align: left;
                    font: normal normal 600 18px/25px Raleway;
                    letter-spacing: 0px;
                    color: #464646;
                    text-decoration: none;
                    @media (max-width: 1100px) {
                        font: normal normal 600 13px/18px Raleway;
                    }
                }
            }
        }
        .box-content{
            display: flex;
            gap: 29px;
            padding-left: 37px;
            height: 134px;
            align-items: center;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 10px 10px 15px #0000001A;
            border-left: 8px solid #D2A771 ;
            width: 105%;

            @media(max-width: 1400px){
                width: 130%;
            }
            @media (max-width: 1100px) {
                width: 100%;
                height: 85px;
                padding-left: 22px;
            }
        }
        .title{
            text-align: left;
            font: normal normal bold 40px/40px "Butler-Regular";
            letter-spacing: 0px;
            color: #997A5D;
            margin-bottom: 50px;
            @media (max-width: 1100px) {
                font-size:  22px;
                line-height:  25px;
                margin-top: 41px;
                padding-inline: 12px;
                margin-bottom: 27px;
            }
        }
        #ct-form{
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-right: 30px;
            column-gap: 7px;
            row-gap: 7px;
            @media (max-width: 1100px) {
                display: flex;
                flex-direction: column;
                padding-inline: 12px;
            }
            .message-area{
                grid-column: span 2;
            }
            .input-group{
                input, select{
                    width: 100%;
                    height: 69px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #D2A771;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    padding-inline: 31px;
                    text-align: left;
                    font: normal normal 600 16px/19px Raleway;
                    letter-spacing: 0px;
                    color: #464646;
                    &::placeholder{
                        text-align: left;
                        font: normal normal 600 16px/19px Raleway;
                        letter-spacing: 0px;
                        color: #464646;
                    }
                }
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background-image: url("../img/arrow-select.png");
                    background-repeat: no-repeat;
                    background-position-x: 91%;
                    background-position-y: 50%;
                }
                textarea{
                    width: 100%;
                    height: 175px;
                    padding-inline: 31px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #D2A771;
                    padding-top: 25px;
                    font: normal normal 600 16px/19px Raleway;
                    letter-spacing: 0px;
                    color: #464646;
                    &::placeholder{
                        text-align: left;
                        font: normal normal 600 16px/19px Raleway;
                        letter-spacing: 0px;
                        color: #464646;
                    }
                }
            }
            .terms-group{
                display: flex;
                align-items: center;
                height: 50px;
                gap: 1rem;
                margin-top: 20px;
            }
            .btn-send{
                width: 202px;
                height: 62px;
                background: #997A5D 0% 0% no-repeat padding-box;
                text-align: left;
                font: normal normal bold 18px/21px Raleway;
                letter-spacing: 0px;
                color: #FFFFFF;
                justify-self: end;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s ease-in;
                margin-top: 18px;
                margin-bottom: 138px;
                @media (max-width: 1100px) {
                    width: 100%;
                    margin-bottom: 46px;
                }
                &:hover{
                    background-color: #D2A771;
                }
            }
        }
    }
}
