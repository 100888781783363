// body.app {
//     background: url('../img/gente.png') top center no-repeat;
// }

.banner-interna {
    padding: 180px 0 91px 0;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1100px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .heading {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: flex;
        flex-direction: column;
    }

    .heading>div {
        overflow: hidden;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(2)>p {
        -webkit-animation-delay: 0.05s;
        animation-delay: 0.05s;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(3)>p {
        -webkit-animation-delay: 0.075s;
        animation-delay: 0.075s;
    }

    .slide-up {
        -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
    }

    @-webkit-keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }
}

h1 {
    font-family: 'Bodoni Moda';
    font-weight: 700;
    font-size: 66px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 42px;

    @media (max-width: 1100px) {
        font-size: 30px;
        line-height: 35px;
    }
}

p {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 27px;
    line-height: 26px;
    color: #FFFFFF;

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.scroll-link {
    display: inline-block;
    margin-top: 80px;
    transition: .3s ease-in;

    &:hover {
        svg path {
            fill: #d9a521;
            transition: .3s ease-in;
        }

    }

    @media (max-width: 1100px) {
        margin-top: 34px;
    }

    .layer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 32%);
        z-index: 1;
    }
}

.content-image {
    display: flex;
    width: 100%;
    height: 627px;
    overflow: hidden;
    position: relative;

}

#pessoas {
    // .container {
    //     padding-left: 20px;
    //     z-index: 2;
    //     position: inherit;
    // }

    .conteudo-section {
        padding: 90px 0;
        position: relative;

        .bloco-imagem {
            max-width: 968px;
            max-height: 643px;
        }

        .bloco-texto {
            position: absolute;
            right: 6px;
            width: 53%;
            padding: 94px 123px 131px 121px;
            top: 163px;
            background-color: #FFFFFF;

            .title-interna {
                margin-bottom: 65px;
            }

            p {
                max-width: 381px;
            }
        }
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #707070;
    }

    .section1 {
        padding: 93px 0 200px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding: 30px 0 50px 0;
        }
        .title-interna {
            padding-left: 123px;
            margin-top: 70px;
            padding-top: 94px;
            margin-bottom: 68px;
            color: #997A5D;
            line-height: 48px;
            @media (max-width: 1100px) {
                margin: 0;
                padding: 0;
                line-height: 25px;
                font-weight: bold;
                margin-bottom: 24px;
                padding-top: 0;
                padding-bottom: 0;
                font-weight: bold;
            }
        }

        p {
            padding-left: 123px;
            max-width: 100%;
            margin-bottom: 32px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .imagem-section {
            width: 968px;
            height: 643px;
            position: absolute;
            left: 330px;
            overflow: hidden;
            @media(max-width: 1600px) {
                left: 100px;
            }
            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                height: 209px;
                object-fit: cover;
                left: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .conteudo-section {
            margin-left: 31px;
            padding: 0;
            position: relative;
            background: #ffffff;
            z-index: 99;
            padding-bottom: 53px;
            @media (max-width: 1100px) {
                padding: 0;
                margin-left: 0;
                padding-inline: 12px;
                margin-top: 33px;
            }
        }
    }

    .section2 {
        padding: 128px 0 140px 0;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        background-attachment: fixed;
        @media (max-width: 1100px) {
            padding: 37px 0 37px 0;
        }
        .container {
            position: relative;
            z-index: 2;
        }

        .title-interna2,
        h3,
        p {
            color: #ffffff;
            text-align: center;
        }

        .title-interna2 {
            margin-bottom: 51px;
            @media (max-width: 1100px) {
                margin-bottom: 0;
            }
        }

        h3 {
            font-family: 'Raleway';
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 48px;
            @media (max-width: 1100px) {
                font: normal normal bold 22px/24px "Butler-Regular";
            }
        }

        p {
            max-width: 900px;
            margin: 0 auto;
            letter-spacing: 1px;
            margin-bottom: 30px;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .btn-interna {
            padding: 21px 120px;
            margin-top: 45px;
            @media (max-width: 1100px) {
                margin-top: 0;
            }
        }

        .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
    }

    .section3 {
        padding: 119px 0 9px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding: 30px 0 50px 0;
        }
        .title-interna {
            padding-left: 109px;
            margin-top: 15px;
            padding-top: 67px;
            margin-bottom: 63px;
            color: #997A5D;
            max-width: 618px;
            line-height: 48px;
            @media (max-width: 1100px) {
                line-height: 25px;
                margin: 0;
                padding: 0;
            }
        }

        p {
            padding-left: 112px;
            max-width: 650px;
            margin-bottom: 32px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                font-size: 14px;
                line-height: 24px;
            }
            strong {
                color: #997A5D;
            }
        }

        .btn-interna {
            margin-left: 109px;
            margin-top: 28px;
            @media (max-width: 1100px) {
                margin-left: 0;
                width: 100%;
            }
        }

        .imagem-section {
            width: 794px;
            height: 671px;
            position: absolute;
            top: 120px;
            right: 376px;
            overflow: hidden;
            @media(max-width: 1600px) {
                width: 794px;
                height: 671px;
                right: 100px;
            }
            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                height: 209px;
                right: 0;
                top: 0;
            }
            figure {
                height: 100%;
                margin: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .conteudo-section {
            margin-left: 31px;
            padding: 0;
            position: relative;
            background: #ffffff;
            z-index: 99;
            padding-bottom: 1px;
            margin-top: 44px;
            max-width: 765px;
            padding-bottom: 150px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                padding-inline: 12px;
                margin-top: 24px;
            }
        }
    }

    .section4 {
        padding: 75px 0 86px 0;
        background-color: #D2A771;
        text-align: center;
        @media (max-width: 1100px) {
            padding: 40px 0 50px 0;
        }
        .title-interna2 {
            font-size: 54px;
            margin-bottom: 39px;
            @media (max-width: 1100px) {
                font: normal normal bold 22px/24px "Butler-Regular";
                margin-bottom: 26px;
            }
        }

        p {
            font-family: 'Raleway';
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #FFFFFF;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .redes-sociais {
            margin-top: 64px;
            display: flex;
            justify-content: center;
            gap: 30px;
            @media (max-width: 1100px) {
                margin-top: 50px;
            }
            a {
                display: inline-block;
                text-decoration: none;
            }

            a.redes:hover svg path,
            a.redes:hover svg ellipse {
                fill: #997A5D;
                transition: .3s ease-in;
            }

            a.youtube:hover svg path {
                stroke: #997A5D !important;
                transition: .3s ease-in;
            }
        }
    }
}
