#who {

    // TÍTULOS
    .banner {
        min-height: 759px;
        padding-top: 177px;
        @media (max-width: 1100px) {
            min-height: auto;
            padding-top: 50px;
            padding-bottom: 45px;
        }
        .content {
            gap: 75px;
            @media (max-width: 1100px) {
                gap: 30px;
            }
        }
    }

    .title-section {
        font-family: 'Butler-Bold';
        font-size: 66px;
        line-height: 65px;
        color: #997A5D;
        text-align: center;

        small {
            font-family: 'Butler-Bold';
            font-size: 48px;
        }
        @media (max-width: 1100px) {
            font: normal normal bold 22px/27px "Butler-Regular";
            font-weight: bold;
            small{
                font: normal normal bold 17px/27px "Butler-Regular";
                font-weight: bold;
            }
        }
    }

    //TEXTOS
    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: left;
    }

    //BOTÕES
    .btns {
        display: inline-block;
        text-decoration: none;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 55px 21px;
        padding: 21px 55px;
        background-color: #997A5D;
        transition: .3s ease-in;

        &:hover {
            transition: .3s ease-in;
            background-color: #D2A771;
        }
    }

    .conteudo-section {
        padding: 90px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 30px;
            padding-bottom: 0;
        }
        .bloco-imagem {
            max-width: 968px;
            max-height: 643px;
        }

        .bloco-texto {
            position: absolute;
            right: 6px;
            width: 70%;
            padding: 94px 123px 131px 121px;
            top: 163px;
            background-color: #FFFFFF;
            z-index: 9;
            left: 48%;
            @media (max-width: 1100px) {
                position: relative;
                right: inherit;
                top: inherit;
                left: inherit;
                padding: 0;
                width: 100%;
            }
            .title-interna {
                margin-bottom: 65px;
                color: #997A5D !important;
                line-height: 57px;

                strong {
                    color: #997A5D !important;
                }
                @media (max-width: 1100px) {
                    line-height: 25px;
                    font-size: 20px;
                    margin-top: 33px;
                    margin-bottom: 24px;
                }
            }

            p {
                font-family: 'Raleway';
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                color: #707070;
                text-align: left;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }


    .section1 {
        padding-bottom: 26px;
        overflow: hidden;
        padding-bottom: 200px;
        @media (max-width: 1100px) {
            padding-bottom: 40px;
        }
        .container {
            padding-left: 20px;
            max-width: 1400px !important;
        }

        .imagem-section {
            overflow: hidden;

            figure {
                margin: 0;
                height: 100%;
            }
        }
    }

    //SECTION 04
    .section4 {
        padding-top: 98px;
        padding-bottom: 0px;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1100px) {
            padding-top: 45px;
            padding-bottom: 220px;
        }
        .title-section {
            color: #ffffff;
            margin-bottom: 17px;
            @media (max-width: 1100px) {
                font: normal normal bold 22px/27px "Butler-Regular";
                font-weight: bold;
                small{
                    font: normal normal bold 17px/27px "Butler-Regular";
                    font-weight: bold;
                }
            }
        }

        p {
            max-width: 849px;
            margin: 0 auto;
            color: #ffffff;
            text-align: center;
        }

        .atrativos {
            margin-top: 79px;
            position: relative;
            z-index: 2;
            transform: translate(0px, 41px);
            z-index: 9999;
            @media (max-width: 1100px) {
                margin-top: 20px;
            }
            .prev {
                position: absolute;
                left: -66px;
                top: 47%;
                @media (max-width: 1100px) {
                    left: 36vw;
                    top: 125%;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 63px;
                        height: 63px;
                        border: 1px solid #D9A521;
                        border-radius: 50%;
                        top: -19px;
                        left: -20px;
                    }
                }
            }

            .next {
                position: absolute;
                right: -66px;
                top: 47%;
                @media (max-width: 1100px) {
                    right: 31vw;
                    top: 125%;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 63px;
                        height: 63px;
                        border: 1px solid #D9A521;
                        border-radius: 50%;
                        top: -19px;
                        left: -26px;
                    }
                }
            }

            .atrativo {
                max-width: 405px;
                height: 518px;
                overflow: hidden;
                @media (max-width: 1100px) {
                    height: 267px;
                }
                // .imagem-slide {
                //     position: absolute;
                //     object-fit: cover;
                //     z-index: 1;
                // }

                .texto-atrativo {
                    position: absolute;
                    height: 142px;
                    bottom: 95px;
                    padding: 20px 36px;
                    z-index: 2;

                    h3 {
                        font-family: 'Raleway';
                        font-weight: 800;
                        font-size: 22px;
                        line-height: 26px;
                        color: #ffffff;
                        height: 55px;
                        @media (max-width: 1100px) {
                            margin-bottom: 0;
                            height: auto;
                            font-size: 17px;
                            line-height: 20px;
                        }
                    }

                    p {
                        font-size: 16px;
                        color: #ffffff;
                        text-align: left;
                        max-width: 355px;
                        line-height: 25px;
                        @media (max-width: 1100px) {
                            font-size:  14px;
                            line-height:  22px;
                            max-width: 75vw;
                            margin-top: 22.4px;
                        }
                    }
                }
            }
        }
    }

    #mapa {
        z-index: 1;
    }
}
