#reservas {
    .banner-interna {
        padding: 180px 0 434px 0;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1100px) {
            padding: 81px 0 156px 0;
        }

        .container {
            padding-left: 20px;
            z-index: 2;
            position: inherit;
        }

        .btn-atrativo {
            display: none;

            @media (max-width: 1100px) {
                display: block;
            }
        }

        .heading {
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            display: flex;
            flex-direction: column;
        }

        .heading>div {
            overflow: hidden;
        }

        div.heading>div {
            -webkit-animation-delay: 0;
            animation-delay: 0;
        }

        div.heading>div:nth-child(2)>p {
            -webkit-animation-delay: 0.05s;
            animation-delay: 0.05s;
        }

        div.heading>div {
            -webkit-animation-delay: 0;
            animation-delay: 0;
        }

        div.heading>div:nth-child(3)>p {
            -webkit-animation-delay: 0.075s;
            animation-delay: 0.075s;
        }

        .slide-up {
            -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
            animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        }

        @-webkit-keyframes slide-up {
            0% {
                transform: translateY(100px);
            }

            100% {
                transform: translateY(0);
            }
        }

        @keyframes slide-up {
            0% {
                transform: translateY(100px);
            }

            100% {
                transform: translateY(0);
            }
        }

        h1 {
            font-family: 'Butler-Bold';
            font-size: 66px;
            line-height: 60px;
            color: #FFFFFF;
            margin-bottom: 102px;

            @media (max-width: 1100px) {
                font: normal normal bold 30px/35px "Butler-Regular";
                margin-bottom: 50px;
            }
        }

        p {
            font-family: 'Raleway';
            font-weight: 500;
            font-size: 27px;
            line-height: 26px;
            color: #FFFFFF;
            text-align: left;

            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
                max-width: 75%;
                margin-bottom: 30px;
            }
        }

        .scroll-link {
            display: inline-block;
            margin-top: 80px;
            transition: .3s ease-in;

            &:hover {
                svg path {
                    fill: #d9a521;
                    transition: .3s ease-in;
                }
            }
        }

        .layer {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #00000094;
            z-index: 1;
        }
    }

    .btn-reservar {
        height: 65px;
        background: #D9A521;
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        font-size: 18px;
        font-family: 'Raleway';
        transform: translate(0px, 36px);
    }

    .logo-reserva {
        @media (max-width: 1100px) {
            width: 79px;
            height: 79px;
            transform: translate(43px, 20px);
        }
    }

    .form-pesquisa {
        max-width: 1391px;
        margin: 0 auto;
        z-index: 2;
        position: relative;
        margin-top: -133px;
        width: 100%;
        padding: 40px 58px;
        background-color: #25224D;

        @media (max-width: 1100px) {
            left: 0;
            padding: 0;
            height: 200px;
            padding-inline: 24px;
            width: calc(100% - 48px);
            background: #25224ded;
        }

        form {
            margin-top: 3px;
            margin-left: 61px;

            .col {
                margin: 0 4px;
            }

            label,
            input,
            select {
                width: 100%;
                font-family: "Raleway";
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #170024;
                border: none;
                position: relative;
            }

            input[type="date"]::before {
                position: absolute;
                left: 16px;
                content: '';
                width: 23px;
                height: 23px;
                background: url('../img/calendar-form.png') center center no-repeat;
                background-size: cover;
            }

            // input[type="date"]::after {
            //     position: absolute;
            //     right: 16px;
            //     content: '';
            //     width: 23px;
            //     height: 23px;
            //     background: url('../img/arrow-select.png') center center no-repeat;
            // }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("../img/arrow-select.png");
                background-repeat: no-repeat;
                background-position-x: 91%;
                background-position-y: 50%;
            }

            .select-icons {
                position: relative;
            }

            .select-icons img {
                position: absolute;
                left: 16px;
                top: 20px;
            }

            input,
            select {
                padding: 21px 23px 21px 55px;
            }

            select option {
                padding: 0;
            }

            input[type="submit"] {
                margin-top: 32px;
                padding: 21px;
                font-family: "Raleway";
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                background-color: #D9A521;
                transition: .3s ease-in;

                &:hover {
                    background-color: #997A5D;
                    transition: .3s ease-in;
                }
            }

            input[type="date"]::-webkit-inner-spin-button,
            input[type="date"]::-webkit-calendar-picker-indicator {
                background: url('../img/arrow-select.png') center center no-repeat;
            }

            label {
                font-family: "Raleway";
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                margin-bottom: 10px;
            }


        }

        .reserva-grupo {
            display: inline-block;
            text-decoration: none;
            text-align: right;
            color: #D9A521;
            margin-right: 20px;

            &:hover {
                color: #D9A521;
            }
        }
    }

    .title-reserva {
        font-family: 'Butler-Bold';
        font-size: 55px;
        line-height: 55px;
        color: #D9A521;
        text-align: center;
    }

    .section1 {
        padding: 93px 0 70px 0;

        @media (max-width: 1100px) {
            padding-top: 32px;
        }

        .title-reserva {
            margin-bottom: 65px;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .blocos {
            margin-left: 22px;

            @media (max-width: 1100px) {
                margin-left: 0;
                padding-inline: 12px;
            }

            .bloco-reserva {
                max-width: 406px;
                margin-bottom: 18px;

                .imagem-reserva {
                    width: 100%;
                    height: 268px;
                    position: relative;
                    overflow: hidden;

                    @media (max-width: 1100px) {
                        height: 100%;

                        figure {
                            margin: 0;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .tag {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        padding: 5px 33px;
                        background-color: #D9A521;

                        transition: .3s ease-in;

                        a {
                            display: inline-block;
                            font-family: 'Raleway';
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            text-decoration: none;
                            color: #FFFFFF;
                        }

                        &:hover {
                            transition: .3s ease-in;
                            background-color: #997A5D;

                            a {
                                color: #ffffff;
                            }
                        }
                    }

                    .faixa {
                        position: absolute;
                        bottom: 0;
                        display: flex;
                        gap: 48px;
                        width: 100%;
                        padding: 14px 30px;
                        background: #04040a;
                        opacity: 0.8;

                        @media (max-width: 1100px) {
                            padding-left: 22px;
                            gap: 27px;
                        }

                        div {
                            display: flex;
                            align-items: center;
                        }

                        p {
                            font-family: 'Raleway';
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                            margin-bottom: 0;

                            @media (max-width: 1100px) {
                                font: normal normal 600 14px/16px Raleway;
                            }
                        }

                        svg {
                            margin-right: 8px;
                        }
                    }
                }

                .conteudo {
                    padding: 26px 33px;
                    color: #FFFFFF;
                    background-color: #25224D;

                    .titulo-bloco {
                        font-family: 'Raleway';
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 26px;
                        margin-bottom: 20px;
                        max-width: 240px;

                        @media (max-width: 1100px) {
                            font: normal normal 800 17px/20px Raleway;
                        }
                    }

                    p {
                        font-family: 'Raleway';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 25px;
                        margin-bottom: 20px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }

                    .valores {
                        display: flex;
                        align-items: center;
                        gap: 24px;

                        .valor {
                            font-family: 'Raleway';
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 26px;

                            @media (max-width: 1100px) {
                                font-size: 18px;
                                line-height: 21px;
                            }
                        }

                        .noite {
                            font-family: 'Raleway';
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            opacity: .6;
                            margin-top: 5px;

                            @media (max-width: 1100px) {
                                font-size: 15px;
                                line-height: 18px;
                            }
                        }
                    }

                    .btn-bloco {
                        display: inline-block;
                        text-decoration: none;
                        font-family: 'Raleway';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        display: inline-block;
                        text-decoration: none;
                        font-family: "Raleway";
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 19px 108px;
                        margin-top: 30px;
                        color: #ffffff;
                        background-color: #D9A521;
                        transition: .3s ease-in;

                        @media (max-width: 1100px) {
                            height: 65px;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &:hover {
                            transition: .3s ease-in;
                            background-color: #997A5D;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    // TÍTULOS
    .title-section {
        font-family: 'Butler-Bold';
        font-size: 66px;
        line-height: 65px;
        color: #997A5D;
        text-align: center;

        small {
            font-family: 'Butler-Bold';
            font-size: 48px;
        }

        @media (max-width: 1100px) {
            font: normal normal bold 22px/27px "Butler-Regular";
            font-weight: bold;

            small {
                font: normal normal bold 17px/27px "Butler-Regular";
                font-weight: bold;
            }
        }
    }

    //TEXTOS
    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;

        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    //BOTÕES
    .btns {
        display: inline-block;
        text-decoration: none;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 55px 21px;
        padding: 21px 55px;
        background-color: #997A5D;
        transition: .3s ease-in;

        @media (max-width: 1100px) {
            font-size: 18px;
        }

        &:hover {
            transition: .3s ease-in;
            background-color: #D2A771;
        }
    }

    // SECTION 01
    .section1-home {
        padding-top: 188px;
        padding-bottom: 115px;

        @media (max-width: 1100px) {
            padding-bottom: 60px;
            padding-top: 145px;
        }

        .title-section {
            margin-bottom: 45px;
            text-transform: uppercase;

            @media (max-width: 1100px) {
                margin-bottom: 30px;
            }
        }

        p {
            color: #464646;
            max-width: 1270px;
            margin: 0 auto;
            text-align: center;

            @media (max-width: 1100px) {
                padding-inline: 12px;
            }
        }

        .btns {
            margin-top: 44px;

            @media (max-width: 1100px) {
                width: calc(100% - 24px);
            }
        }
    }
}
