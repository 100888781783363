#home {

    // BANNER
    .banner-home {
        position: relative;

        .heading {
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            display: flex;
            flex-direction: column;

            @media(max-width: 991px){
                max-width: 80%;
            }
        }

        .heading>div {
            overflow: hidden;
        }

        div.heading>div {
            -webkit-animation-delay: 0;
            animation-delay: 0;
        }

        div.heading>div:nth-child(2)>p {
            -webkit-animation-delay: 0.05s;
            animation-delay: 0.05s;
        }

        div.heading>div {
            -webkit-animation-delay: 0;
            animation-delay: 0;
        }

        div.heading>div:nth-child(3)>p {
            -webkit-animation-delay: 0.075s;
            animation-delay: 0.075s;
        }

        .slide-up {
            -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
            animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        }

        @-webkit-keyframes slide-up {
            0% {
                transform: translateY(100px);
            }

            100% {
                transform: translateY(0);
            }
        }

        @keyframes slide-up {
            0% {
                transform: translateY(100px);
            }

            100% {
                transform: translateY(0);
            }
        }
    }

    #carouselHome {
        .carousel-indicators {
            margin-left: 0;
            margin-right: 0;
            justify-content: start;
            left: inherit;
            right: inherit;
            bottom: 144px;
            margin-left: 7px;

            @media (max-width: 1100px) {
                bottom: 114px;
                left: 39vw;
            }

            [data-bs-target] {
                width: 9px;
                height: 9px;
                border-top: 0;
                border-bottom: 0;
                border-radius: 30px;
                margin-right: 15px;
                margin-left: 0;
                opacity: 1;
            }

            .active {
                background-color: #D2A771;
            }
        }

        .carousel-inner {
            height: 755px;

            @media (max-width: 1100px) {
                height: 554px;
            }
        }

        .carousel-item {
            height: 100%;
            position: relative;

            .layer {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.53) 0%, rgba(33, 32, 32, 0) 100%);
                z-index: 1;

                @media (max-width: 1100px) {
                    pointer-events: none;
                }
            }

            img {
                height: 100%;
                object-fit: cover;
            }

            .carousel-caption {
                bottom: inherit;
                top: 100px;
                text-align: left;
                left: 13px;
                right: 0;
                z-index: 2;

                @media (max-width: 1100px) {
                    top: 50px;
                }

                h2 {
                    max-width: 640px;
                    font-family: 'Butler-Bold';
                    font-size: 66px;
                    line-height: 60px;
                    margin-bottom: 64px;

                    @media (max-width: 1100px) {
                        text-align: left;
                        font-size: 30px;
                        line-height: 35px;
                        margin-bottom: 38px;
                    }
                }

                p {
                    max-width: 440px;
                    font-family: 'Raleway';
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 32px;
                    text-align: left;

                    @media (max-width: 1100px) {
                        font-size: 14px;
                        line-height: 24px;
                        max-width: 60vw;
                    }
                }
            }
        }


        @-webkit-keyframes zoom {
            from {
                -webkit-transform: scale(1, 1);
            }

            to {
                -webkit-transform: scale(1.2, 1.2);
            }
        }

        @keyframes zoom {
            from {
                transform: scale(1, 1);
            }

            to {
                transform: scale(1.2, 1.2);
            }
        }

        .carousel-inner .carousel-item>img {
            -webkit-animation: zoom 20s;
            animation: zoom 20s;
        }



        .carousel-item.active img {
            transition: transform 8000ms linear 0s;
            transform: scale(1.2, 1.2);
        }

        @media all and (transform-3d),
        (-webkit-transform-3d) {

            .carousel-fade .carousel-inner>.carousel-item.next,
            .carousel-fade .carousel-inner>.carousel-item.active.right {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            .carousel-fade .carousel-inner>.carousel-item.prev,
            .carousel-fade .carousel-inner>.carousel-item.active.left {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            .carousel-fade .carousel-inner>.carousel-item {
                transition: opacity 2s ease;
            }

            .carousel-fade .carousel-inner>.carousel-item.next.left,
            .carousel-fade .carousel-inner>.carousel-item.prev.right,
            .carousel-fade .carousel-inner>.carousel-item.active {
                opacity: 1;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .btn-reservar {
        height: 65px;
        background: #D9A521;
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        font-size: 18px;
        font-family: 'Raleway';
        transform: translate(0px, 36px);
    }

    .logo-reserva {
        @media (max-width: 1100px) {
            width: 79px;
            height: 79px;
            transform: translate(43px, 20px);
        }
    }

    .form-pesquisa {
        max-width: 1391px;
        margin: 0 auto;
        z-index: 2;
        position: absolute;
        bottom: -97px;
        width: 100%;
        left: 17px;
        right: 0;
        padding: 40px 58px;
        background-color: #25224D;

        @media (max-width: 1100px) {
            left: 0;
            padding: 0;
            height: 200px;
            padding-inline: 24px;
            width: calc(100% - 48px);
            background: #25224ded;
        }

        form {
            margin-top: 3px;
            margin-left: 61px;

            .col {
                margin: 0 4px;
            }

            label,
            input,
            select {
                width: 100%;
                font-family: "Raleway";
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #170024;
                border: none;
                position: relative;
            }

            input[type="date"]::before {
                position: absolute;
                left: 16px;
                content: '';
                width: 23px;
                height: 23px;
                background: url('../img/calendar-form.png') center center no-repeat;
                background-size: cover;
            }

            // input[type="date"]::after {
            //     position: absolute;
            //     right: 16px;
            //     content: '';
            //     width: 23px;
            //     height: 23px;
            //     background: url('../img/arrow-select.png') center center no-repeat;
            // }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("../img/arrow-select.png");
                background-repeat: no-repeat;
                background-position-x: 91%;
                background-position-y: 50%;
            }

            .select-icons {
                position: relative;
            }

            .select-icons img {
                position: absolute;
                left: 16px;
                top: 20px;
            }

            input,
            select {
                padding: 21px 13px 21px 55px;
            }

            select option {
                padding: 0;
            }

            input[type="submit"] {
                margin-top: 32px;
                padding: 21px;
                font-family: "Raleway";
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                background-color: #D9A521;
                transition: .3s ease-in;

                &:hover {
                    background-color: #997A5D;
                    transition: .3s ease-in;
                }
            }

            input[type="date"]::-webkit-inner-spin-button,
            input[type="date"]::-webkit-calendar-picker-indicator {
                background: url('../img/arrow-select.png') center center no-repeat;
            }

            label {
                font-family: "Raleway";
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                margin-bottom: 10px;
            }


        }

        .reserva-grupo {
            display: inline-block;
            text-decoration: none;
            text-align: right;
            color: #D9A521;
            margin-right: 20px;

            &:hover {
                color: #D9A521;
            }
        }
    }

    // TÍTULOS
    .title-section {
        font-family: 'Butler-Bold';
        font-size: 66px;
        line-height: 65px;
        color: #997A5D;
        text-align: center;

        small {
            font-family: 'Butler-Bold';
            font-size: 48px;
        }

        @media (max-width: 1100px) {
            font: normal normal bold 22px/27px "Butler-Regular";
            font-weight: bold;

            small {
                font: normal normal bold 17px/27px "Butler-Regular";
                font-weight: bold;
            }
        }
    }

    //TEXTOS
    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;

        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    //BOTÕES
    .btns {
        display: inline-block;
        text-decoration: none;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 55px 21px;
        padding: 21px 55px;
        background-color: #997A5D;
        transition: .3s ease-in;

        @media (max-width: 1100px) {
            font-size: 18px;
        }

        &:hover {
            transition: .3s ease-in;
            background-color: #D2A771;
        }
    }

    // SECTION 01
    .section1 {
        padding-top: 188px;
        padding-bottom: 115px;

        @media (max-width: 1100px) {
            padding-bottom: 60px;
            padding-top: 145px;
        }

        .title-section {
            margin-bottom: 45px;
            text-transform: uppercase;

            @media (max-width: 1100px) {
                margin-bottom: 30px;
            }
        }

        p {
            color: #464646;
            max-width: 1270px;
            margin: 0 auto;

            @media (max-width: 1100px) {
                padding-inline: 12px;
            }
        }

        .btns {
            margin-top: 44px;

            @media (max-width: 1100px) {
                width: calc(100% - 24px);
            }
        }
    }

    // SECTION 02
    .section2 {
        padding-top: 111px;
        padding-bottom: 119px;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        @media (max-width: 1100px) {
            padding-top: 55px;
            padding-bottom: 45px;
        }

        .container {
            z-index: 2;
            position: relative;
        }

        .title-section {
            color: #ffffff;
            margin-bottom: 47px;
        }

        p {
            max-width: 801px;
            margin: 0 auto;
            color: #ffffff;
        }

        .btns {
            background-color: #ffffff;
            color: #997A5D;
            margin-top: 75px;
            padding: 21px 38px;
            transition: .3s ease-in;

            @media (max-width: 1100px) {
                margin-top: 37px;
            }

            &:hover {
                transition: .3s ease-in;
                color: #ffffff;
                background-color: #D2A771;
            }
        }

        .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
    }

    //SECTION 03
    .section3 {
        padding-top: 94px;
        padding-bottom: 129px;

        @media (max-width: 1100px) {
            padding-top: 55px;
            padding-bottom: 61px;
        }

        .title-section {
            text-transform: uppercase;
            margin-bottom: 45px;

            @media (max-width: 1100px) {
                margin-bottom: 30px;
            }

        }

        p {
            font-size: 22px;
            max-width: 913px;
            margin: 0 auto;

            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .blocos {
            margin-top: 87px;

            @media (max-width: 1100px) {
                margin-top: 0px;
            }

            .bloco {
                max-width: 625px;
                transition: all .5s ease;
                overflow: hidden;

                &:hover {
                    transform: translateY(-10px);
                    -webkit-box-shadow: 0px 40px 60px #0000000D;
                    -moz-box-shadow: 0px 40px 60px #0000000D;
                    box-shadow: 0px 40px 60px #0000000D;
                    cursor: pointer;
                }

                .imagem-bloco {
                    width: 100%;
                    height: 399px;

                    @media (max-width: 1100px) {
                        height: auto;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .texto {
                    padding: 49px 47px;
                    -webkit-box-shadow: 0px 20px 30px #0000000D;
                    -moz-box-shadow: 0px 20px 30px #0000000D;
                    box-shadow: 0px 20px 30px #0000000D;
                    height: 440px;

                    @media (max-width: 1100px) {
                        padding-inline: 12px;
                        height: auto;
                        padding: 20px 47px;
                    }

                    h3 {
                        font-family: 'Butler-Regular';
                        font-size: 30px;
                        font-weight: 400;
                        line-height: 26px;
                        color: #997A5D;
                        text-align: center;
                        margin-bottom: 14px;
                        height: 63px;

                        @media (max-width: 1100px) {
                            font-size: 17px;
                            line-height: 18px;
                            margin-bottom: 22px;
                            height: auto;
                        }
                    }

                    p {
                        font-size: 20px;
                        color: #707070;
                        height: 120px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 24px;
                            height: auto;
                        }
                    }

                    .btns {
                        margin-top: 43px;
                        padding: 21px 125px;

                        @media (max-width: 1100px) {
                            padding: 0;
                            height: 65px;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 23px;
                        }
                    }
                }

            }
        }
    }

    //SECTION 04
    .section4 {
        padding-top: 98px;
        padding-bottom: 0px;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1100px) {
            padding-top: 45px;
            padding-bottom: 220px;
        }

        .title-section {
            color: #ffffff;
            margin-bottom: 17px;
        }

        p {
            max-width: 849px;
            margin: 0 auto;
            color: #ffffff;
        }

        .atrativos {
            margin-top: 79px;
            position: relative;
            z-index: 2;

            @media (max-width: 1100px) {
                margin-top: 20px;
            }

            .prev {
                position: absolute;
                left: -66px;
                top: 47%;

                @media (max-width: 1100px) {
                    left: 36vw;
                    top: 125%;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 63px;
                        height: 63px;
                        border: 1px solid #D9A521;
                        border-radius: 50%;
                        top: -19px;
                        left: -20px;
                    }
                }
            }

            .next {
                position: absolute;
                right: -66px;
                top: 47%;

                @media (max-width: 1100px) {
                    right: 31vw;
                    top: 125%;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 63px;
                        height: 63px;
                        border: 1px solid #D9A521;
                        border-radius: 50%;
                        top: -19px;
                        left: -26px;
                    }
                }
            }

            .atrativo {
                max-width: 405px;
                height: 518px;
                overflow: hidden;
                position: relative;

                @media (max-width: 1100px) {
                    height: 267px;
                }

                figure,
                .hover01{
                    height: 100%;
                }

                figure img{
                    height: 100%;
                    object-fit: cover;
                }
                // .imagem-slide {
                //     position: absolute;
                //     object-fit: cover;
                //     z-index: 1;
                // }

                .texto-atrativo {
                    position: absolute;
                    height: 260px;
                    bottom: 0px;
                    padding: 20px 36px;
                    z-index: 2;

                    h3 {
                        font-family: 'Raleway';
                        font-weight: 800;
                        font-size: 22px;
                        line-height: 26px;
                        color: #ffffff;
                        height: 55px;

                        @media (max-width: 1100px) {
                            margin-bottom: 0;
                            height: auto;
                            font-size: 17px;
                            line-height: 20px;
                        }
                    }

                    p {
                        font-size: 16px;
                        color: #ffffff;
                        text-align: left;
                        max-width: 355px;
                        line-height: 25px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 22px;
                            max-width: 75vw;
                            margin-top: 22.4px;
                        }
                    }
                }
            }
        }
    }

    //SECTION 05
    .section5 {
        padding-top: 157px;
        padding-bottom: 158px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: 1;
        margin-top: -51px;

        @media (max-width: 1100px) {
            padding-top: 67px;
            padding-bottom: 67px;
        }

        p {
            text-align: center;
            font-size: 22px;
            line-height: 26px;
            color: #ffffff;
            margin-bottom: 53px;

            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 24px;
            }
        }

        h3 {
            font-family: 'Butler-Regular';
            font-size: 85px;
            line-height: 80px;
            text-transform: uppercase;
            text-align: center;
            color: #ffffff;
            margin-bottom: 44px;

            @media (max-width: 1100px) {
                font: normal normal normal 40px/45px "Butler-Regular";
            }
        }

        .btns {
            color: #D2A771;
            background-color: #ffffff;
            padding: 21px 69px;
            transition: .3s ease-in;

            &:hover {
                transition: .3s ease-in;
                color: #ffffff;
                background-color: #D2A771;
            }
        }

        .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: -1;
        }
    }
}