body{
    zoom: .8;
    @media (max-width: 768px){
        zoom: 1;
    }
}

.form-group {
    position: relative;
    margin: 0;
}

input.error,
textarea.error,
select.error {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
}

label.error {
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
}

.grecaptcha-badge {
    display: none !important;
}

img {
    max-width: 100%;
}

.title-interna,
.title-interna strong{
    font-family: 'Butler-Bold';
    font-size: 54px;
    line-height: 40px;
    color: #25224D;
    @media (max-width: 768px){
        font-size:  22px;
        line-height: 25px;
        font-weight: bold;
    }
}

.title-interna2 {
    font-family: 'Butler-Bold';
    font-size: 66px;
    line-height: 55px;
    color: #FFFFFF;
    @media (max-width: 768px){
        font-size:  22px;
        line-height: 24px;
        font-weight: bold;
    }
}


//BOTÕES
.btn-interna {
    display: inline-block;
    text-decoration: none;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    padding: 55px 21px;
    padding: 21px 55px;
    background-color: #997A5D;
    transition: .3s ease-in;

    &:hover {
        transition: .3s ease-in;
        background-color: #D2A771;
        color: #ffffff;
    }
}

/* Zoom In #1 */
.hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out !important;
    transition: .3s ease-in-out !important;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition: .3s ease-in-out !important;
}

/*Shine*/
.hover14 figure {
    position: relative;
    margin: 0;
}

.hover14 figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.hover14 figure:hover::before {
    -webkit-animation: shine .75s !important;
    animation: shine .75s !important;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

#open_preferences_center{
    display: none;
}
