header {
    background-color: #ffffff;

    &.fixed {
        position: fixed;
        width: 100%;
        background-color: #ffffff;
        z-index: 999999;
        box-shadow: 0px 20px 30px #0000000D;

        @media (max-width: 1100px) {
            border-bottom: .5px solid #707070;
        }

        .topo {
            .logo {
                max-width: 108px;

                @media (max-width: 1100px) {
                    max-width: 76px;
                }
            }

            .menu-icon {
                margin-top: 28px;

                @media (max-width: 1100px) {
                    margin-top: 0;
                }
            }
        }
    }

    .topo {
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
        margin-bottom: 6px;
        background-color: #ffffff;

        @media (max-width: 1100px) {
            align-items: center;
            padding: 25px 10px;
        }

        .btn-reservar {
            display: none;

            @media (max-width: 1100px) {
                display: flex;
                gap: 8.7px;
                justify-content: center;
                align-items: center;
                padding: 10px 26px;
                background: #D9A521;
                text-decoration: none;
                color: #ffffff;
                font-family: 'Raleway';
                font-weight: bold;
                font-size: 18px;
                line-height: 18px;
                height: 42px;
            }
        }

        .logo {
            margin-left: 53px;

            @media (max-width: 1100px) {
                margin-left: 0;
                max-width: 76px;
            }
        }

        .menu-icon {
            margin-top: 48px;

            @media (max-width: 1100px) {
                margin-top: 0;
            }
        }
    }

    .menu {
        border-top: solid 1px #707070;
        background-color: #ffffff;

        @media (max-width: 1100px) {
            display: none;
        }

        nav {
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;

            .navbar-nav {
                width: 100%;
                justify-content: space-between;
                padding: 0;
            }

            .nav-item a {
                text-transform: uppercase;
                font-family: 'Butler-Regular';
                font-size: 23px;
                color: #121212;
                line-height: 26px;
                letter-spacing: 2px;
                position: relative;
                transition: .3s ease-in;

                &:hover {
                    transition: .3s ease-in;
                    color: #997A5D;
                }

                &.active {
                    color: #997A5D;
                }

                &::after {
                    position: absolute;
                    content: '';
                    width: 8px;
                    height: 7px;
                    top: 43%;
                    right: -25px;
                    background: url('../img/circle.png') center center no-repeat;
                    background-size: cover;
                }
            }

            .nav-item:last-child a::after {
                display: none;
            }
        }
    }

    .box {
        float: left;
        overflow: hidden;
        background: #00000094;
        position: absolute;
        width: 100%;
        height: 125vh;
        top: 0;
        right: 0;
        z-index: 999;
    }

    .box-inner {
        width: 708px;
        float: right;
        right: 0;
        background: #EAEAEA;
        height: 125vh;
        overflow: auto;

        @media (max-width: 1100px) {
            width: 100%;
            height: 100dvh;
        }

        .container {
            background-color: #ffffff;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #997A5D;
        }

        .topo-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 80px 22px 80px;

            @media (max-width: 1100px) {
                padding: 25px 10px;
            }

            .logo-inside {
                @media (max-width: 1100px) {
                    max-width: 76px;
                }
            }

            .slide-toggle {
                margin-right: 15px;
            }
        }

        .border {
            width: 100%;
            height: 1px;
            background-color: #707070;
        }

        .desk-menu {
            display: block;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .mobile-menu {
            display: none;

            @media (max-width: 1100px) {
                display: block;
            }
        }

        .links {
            padding: 60px 66px 54px 66px;
            background-color: #ffffff;

            @media (max-width: 1100px) {
                padding: 0;
                padding-top: 34px;
                padding-bottom: 34px;
            }

            ul li a {
                display: inline-block;
                text-decoration: none;
                font-family: 'Raleway';
                font-size: 19px;
                font-weight: 500;
                line-height: 22px;
                color: #121212;
                margin-bottom: 40px;
                letter-spacing: 2px;
                transition: .3s ease-in;

                @media (max-width: 1100px) {
                    letter-spacing: 1.6px;
                    font-size: 16px;
                    font-family: 'Butler-Regular';
                    text-transform: uppercase;
                    margin-bottom: 49px;
                }

                &:hover {
                    transition: .3s ease-in;
                    color: #997A5D;
                }
            }

           /* ul li {
                &:first-child {
                    a {
                        color: #997A5D;
                    }
                }

                &:last-child {
                    a {
                        color: #997A5D;
                    }
                }
            }*/
        }

        .politicas {
            padding: 92px 90px 143px 90px;
            background-color: #EAEAEA;

            @media (max-width: 1100px) {
                padding: 0;
                padding-left: 24px;
                padding-top: 40px;
            }

            ul {
                padding-left: 0;

                @media (max-width: 1100px) {
                    margin-bottom: 0;
                }

                li a {
                    display: inline-block;
                    text-decoration: none;
                    font-family: 'Raleway';
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 22px;
                    color: #121212;
                    margin-bottom: 40px;
                    letter-spacing: 2px;
                    transition: .3s ease-in;

                    &:hover {
                        transition: .3s ease-in;
                        color: #997A5D;
                    }

                    @media (max-width: 1100px) {
                        margin-bottom: 43px;
                    }
                }
            }

            .btns {
                display: inline-block;
                font-family: 'Raleway';
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                padding: 21px 38px;
                text-decoration: none;
                margin-top: 83px;
                background-color: #D9A521;
                color: #ffffff;
                transition: .3s ease-in;

                @media (max-width: 1100px) {
                    display: none;
                }

                &:hover {
                    transition: .3s ease-in;
                    background-color: #25224D;
                }
            }

            .redes-sociais {
                margin-top: 0px;
                display: flex;
                justify-content: start;
                gap: 40px;
            }
        }

        .reserva-mobile-menu {
            display: none;

            @media (max-width: 1100px) {
                height: 237px;
                background: #25224D;
                display: flex;
                flex-direction: column;
                gap: 16px;
                position: relative;
                left: -24px;
                width: calc(100% + 24px);
                justify-content: center;
                align-items: center;
            }

            img {
                width: 80px;
                height: 80px;
            }

            a {
                display: flex;
                gap: 8.7px;
                background: #D9A521;
                color: #fff;
                text-decoration: none;
                font: normal normal bold 18px/18px Raleway;
                justify-content: center;
                align-items: center;
                height: 65px;
                width: calc(100% - 48px);
                margin: 0 auto;
            }
        }

        .redes-menu-mobile {
            display: none;

            @media (max-width: 1100px) {
                height: 140px;
                background: #EAEAEA;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 39px;
                left: -24px;
                position: relative;
            }
        }
    }

    .slide-toggle {
        cursor: pointer;
    }
}

.reserve-agr {
    position: fixed;
    right: -99px;
    top: 50%;
    z-index: 99;
    vertical-align: middle;
    display: table-cell;
    transform: rotate(-90deg);
    font-family: "Raleway";
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: #D9A521;
    padding: 19px 31px 12px 31px;
    text-decoration: none;
    background-color: #25224D;
    z-index: 99;
    transition: .3s ease-in;

    @media (max-width: 1100px) {
        display: none;
    }
    svg {
        transform: rotate(90deg);
        margin-right: 15px;
        transition: .3s ease-in;
    }

    &:hover {
        background-color: #D9A521;
        color: #25224D;
        transition: .3s ease-in;

        svg path {
            fill: #25224D;
            transition: .3s ease-in;
        }
    }
}
