#pagePolitic {

    .content-politic {
        padding-top: 75px;
        padding-bottom: 140px;
        @media (max-width: 1100px) {
            padding-inline: 12px;
            padding-top: 40px;
            padding-bottom: 120px;
        }
        .title {
            text-align: left;
            font: normal normal bold 40px "Butler-Regular";
            letter-spacing: 0px;
            color: #997A5D;
            @media (max-width: 1100px) {
                font: normal normal bold 30px/35px "Butler-Regular";
            }
        }

        .text {
            text-align: left;
            font: normal normal bold 20px Raleway;
            letter-spacing: 0px;
            color: #707070;
            margin-top: 63px;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
                margin-top: 35px;
            }
            ul{
               @media (max-width: 1100px) {
                padding-left: 0;
               }
            }
        }

        li {
            color: inherit;
            position: relative;
            padding-left: 35px;
            margin-bottom: 10px;

            &::before {
                content: '//';
                position: absolute;
                width: 30px;
                height: 30px;
                color: #D2A771;
                font-weight: 700;
                left: 0px;
            }
        }
    }

    .content-white {
        background: #fff;
    }

    .content-brow {
        background: #EDEDED;
    }
}
