///////////////////////////////////
////////// FONT-FACE //////////////
///////////////////////////////////

//SE FOR GOOGLEFONTES COLOCAR NO APP.BLADE.PHP

@font-face {
	font-family: 'Butler-Bold';
	src: url('../fonts/butler-font/Butler_Bold.otf');
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Butler-Regular';
	src: url('../fonts/butler-font/Butler_Regular.otf');
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

*{
    font-family: 'Raleway';
    font-weight: 400;
}

@mixin fontTemplate($size, $height, $color, $weight, $family, $align: start) {
    font-size: $size;
    line-height: $height;
    color: $color;
    font-weight: $weight;
    font-family: $family;
	text-align: $align;
}
