#reservasDetail {
    h1 {
        font-family: 'Butler-Bold';
        font-size: 35px;
        line-height: 40px;
        color: #D9A521;
        text-align: left;
    }

    .title-reserva {
        font-family: 'Butler-Bold';
        font-size: 55px;
        line-height: 55px;
        color: #D9A521;
        text-align: center;
    }

    .section1 {
        padding: 65px 0 0 0;

        .voltar {
            display: inline-block;
            text-decoration: none;
            font-family: 'Raleway';
            font-weight: 500;
            font-size: 19px;
            line-height: 21px;
            color: #707070;
            padding-bottom: 15px;
            border-bottom: solid 1px #707070;
            transition: .3s ease-in;
            margin-top: 14px;

            &:hover {
                transition: .3s ease-in;
                color: #25224D;
            }
        }

        .reservar {
            display: inline-block;
            text-decoration: none;
            font-family: 'Raleway';
            font-weight: 600;
            font-size: 22px;
            color: #D9A521;
            background-color: #25224D;
            padding: 15px 26px;
            transition: .3s ease-in;

            svg {
                transform: rotate(90deg);
                margin-right: 9px;
                transition: .3s ease-in;
            }

            &:hover {
                transition: .3s ease-in;
                color: #25224D;
                background-color: #D9A521;

                svg path {
                    fill: #25224D;
                    transition: .3s ease-in;
                }
            }
        }

        .galeria {
            margin-top: 37px;

            .col-lg-7{
                padding: 0;
            }

            .card-principal {
                width: 100%;
                height: 466px;
                overflow: hidden;


                a {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .card {
                width: 272px;
                height: 228px;
                margin-bottom: 10px;
                overflow: hidden;

                a {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .sobre {
            padding: 40px 0 88px 0;

            h2 {
                font-family: 'Butler-Regular';
                font-size: 25px;
                line-height: 26px;
                color: #25224D;
                margin-bottom: 40px;
            }

            p {
                font-family: 'Raleway';
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                color: #707070;
                max-width: 570px;
            }

            .diferencial {
                display: flex;
                align-items: center;
                gap: 15px;
                align-items: center;
                margin-bottom: 31px;

                p {
                    font-family: 'Raleway';
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 22px;
                    color: #707070;
                    margin-bottom: 0;
                }
            }
        }
    }

    .section2 {
        padding: 144px 0 150px 0;
        background-color: #F4F4F4;

        .title-reserva {
            margin-bottom: 76px;
        }

        .blocos {
            margin-left: 22px;

            .bloco-reserva {
                max-width: 406px;
                margin-bottom: 18px;

                .imagem-reserva {
                    width: 100%;
                    height: 268px;
                    position: relative;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .tag {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        padding: 5px 33px;
                        background-color: #D9A521;

                        transition: .3s ease-in;

                        a {
                            display: inline-block;
                            font-family: 'Raleway';
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            text-decoration: none;
                            color: #FFFFFF;
                        }

                        &:hover {
                            transition: .3s ease-in;
                            background-color: #997A5D;

                            a {
                                color: #ffffff;
                            }
                        }
                    }

                    .faixa {
                        position: absolute;
                        bottom: 0;
                        display: flex;
                        gap: 48px;
                        width: 100%;
                        padding: 14px 30px;

                        background: #04040a;
                        opacity: 0.8;

                        div {
                            display: flex;
                            align-items: center;
                        }

                        p {
                            font-family: 'Raleway';
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                            margin-bottom: 0;
                        }

                        svg {
                            margin-right: 8px;
                        }
                    }
                }

                .conteudo {
                    padding: 26px 33px;
                    color: #FFFFFF;
                    background-color: #25224D;

                    .titulo-bloco {
                        font-family: 'Raleway';
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 26px;
                        margin-bottom: 20px;
                        max-width: 240px;
                    }

                    p {
                        font-family: 'Raleway';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 25px;
                        margin-bottom: 20px;
                    }

                    .valores {
                        display: flex;
                        align-items: center;
                        gap: 24px;

                        .valor {
                            font-family: 'Raleway';
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 26px;
                        }

                        .noite {
                            font-family: 'Raleway';
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            opacity: .6;
                            margin-top: 5px;
                        }
                    }

                    .btn-bloco {
                        display: inline-block;
                        text-decoration: none;
                        font-family: 'Raleway';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        display: inline-block;
                        text-decoration: none;
                        font-family: "Raleway";
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 19px 108px;
                        margin-top: 30px;
                        color: #ffffff;
                        background-color: #D9A521;
                        transition: .3s ease-in;

                        &:hover {
                            transition: .3s ease-in;
                            background-color: #997A5D;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
