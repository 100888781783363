.banner-interna {
    padding: 180px 0 91px 0;
    position: relative;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .heading {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: flex;
        flex-direction: column;
    }

    .heading>div {
        overflow: hidden;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(2)>p {
        -webkit-animation-delay: 0.05s;
        animation-delay: 0.05s;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(3)>p {
        -webkit-animation-delay: 0.075s;
        animation-delay: 0.075s;
    }

    .slide-up {
        -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
    }

    @-webkit-keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    .container {
        padding-left: 20px;
        z-index: 2;
        position: inherit;
    }

    h1 {
        font-family: 'Butler-Bold';
        font-size: 66px;
        line-height: 60px;
        color: #FFFFFF;
        margin-bottom: 42px;
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 27px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .scroll-link {
        display: inline-block;
        margin-top: 80px;
        transition: .3s ease-in;

        &:hover {
            svg path {
                fill: #d9a521;
                transition: .3s ease-in;
            }
        }
    }

    .layer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #00000094;
        z-index: 1;
    }
}

#hotel {

    .conteudo-section {
        padding: 90px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .bloco-imagem {
            max-width: 968px;
            max-height: 643px;
        }

        .bloco-texto {
            position: absolute;
            right: 6px;
            width: 53%;
            padding: 94px 123px 131px 121px;
            top: 163px;
            background-color: #FFFFFF;
            z-index: 9;
            @media (max-width: 1100px) {
                position: relative;
                right: inherit;
                top: inherit;
                padding: 0;
                margin: 0;
                width: 100%;
            }
            .title-interna {
                margin-bottom: 65px;
                @media (max-width: 1100px) {
                    margin-bottom: 24px;
                    margin-top: 30px;
                    font: normal normal bold 22px/25px "Butler-Regular";
                }
            }

            p {
                max-width: 381px;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #707070;
        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .section1 {
        padding-bottom: 26px;

        .container {
            padding-left: 20px;
        }

        .imagem-section {
            overflow: hidden;

            figure {
                margin: 0;
                height: 100%;
            }
        }
    }

    .section2 {
        padding: 172px 0 198px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
        @media (max-width: 1100px) {
            padding: 40px 0;
        }
        .container {
            position: relative;
            z-index: 2;
        }

        .title-interna2,
        p {
            color: #ffffff;
            text-align: center;
        }

        .title-interna2 {
            margin-bottom: 51px;
        }

        p {
            max-width: 801px;
            margin: 0 auto;
            letter-spacing: 1px;
        }

        .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
    }

    .section3 {
        padding: 98px 0 228px 0;
        position: relative;
        @media (max-width: 1100px) {
            padding: 40px 0;
        }
        .title-interna {
            padding-left: 140px;
            padding-top: 113px;
            margin-bottom: 72px;
            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                margin-top: 30px;
                margin-bottom: 24px;
            }
        }

        p {
            padding-left: 140px;
            max-width: 511px;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
                padding: 0;
            }
        }

        .imagem-section {
            width: 794px;
            height: 673px;
            overflow: hidden;
            margin-left: 50px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 1100px) {
                width: 100%;
                height: 209px;
                margin: 0;
            }
        }
    }

    .section2.section4 {
        padding: 179px 0 192px 0;
        @media (max-width: 1100px) {
            padding: 40px 0;
        }
        .title-interna2 {
            margin-left: 46px;
            @media (max-width: 1100px) {
                margin: 0;
                margin-bottom: 24px;
            }
        }

        p {
            max-width: 801px;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .section5 {
        padding: 91px 0 162px 0;
        @media (max-width: 1100px) {
            padding: 40px 0;
        }
        .title-interna {
            text-align: center;
            text-transform: uppercase;
            font-size: 55px;
            line-height: 55px;
            margin-bottom: 86px;

            small {
                font-family: 'Butler-Bold';
                font-size: 40px;
                line-height: 55px;
            }
            @media (max-width: 1100px) {
                font: normal normal bold 22px/27px "Butler-Regular";
                font-weight: bold;
                color: #997A5D;
                margin-bottom: 40px;
                small{
                    font: normal normal bold 17px/27px "Butler-Regular";
                    font-weight: bold;
                }
            }
        }

        .quartos {
            margin-bottom: 63px;

            .acomodacao {
                width: 100%;
                height: 368px;
                position: relative;

                margin-bottom: 26px;

                .imagem-section {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .btn-interna {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 25px;
                    max-width: 342px;
                    margin: 0 auto;
                    background-color: #D9A521;
                    z-index: 9;

                    &:hover {
                        background-color: #25224D;
                        color: #ffffff;
                    }
                }
            }
        }

        .btn-interna.reserva {
            margin: 0 auto;
            display: flex;
            max-width: 300px;
            background-color: #25224D;
            color: #D9A521;
            padding: 21px 35px;
            font-size: 22px;
            font-weight: 600;

            svg {
                margin-right: 15px;
                transition: .3s ease-in;
            }

            &:hover {
                color: #25224D;
                background-color: #D9A521;

                svg path {
                    fill: #25224D;
                    transition: .3s ease-in;
                }
            }
        }

    }

    .section6 {
        position: relative;
        padding: 538px 0 94px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1100px) {
            padding: 40px 0;
            .row-imgs-servicos{
                flex-wrap: nowrap;
            }
        }
        .imagens-servicos {
            position: absolute;
            top: -80px;
            @media (max-width: 1100px) {
                position: relative;
                top: inherit;
                width: 100%;
                overflow: scroll;
            }
            .imagem-section {
                overflow: hidden;
            }

            figure {
                margin: 0;
                @media (max-width: 1100px) {
                    img{
                        height: 290px;
                        width: 230px;
                    }
                }
            }
        }

        .title-interna2 {
            max-width: 850px;
            margin-left: 5px;
            margin-bottom: 90px;
            @media (max-width: 1100px) {
                max-width: 100%;
                margin: 0;
                margin-bottom: 40px;
                margin-top: 40px;
            }
        }

        .lista-servicos {
            display: flex;
            justify-content: space-between;
            @media (max-width: 1100px) {
                gap: 3rem;
                width: 100%;
                overflow: scroll;
            }
            ul {
                padding-left: 5px;

                li {
                    font-family: 'Raleway';
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 26px;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    gap: 22px;
                    margin-bottom: 30px;
                    @media (max-width: 1100px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    img {
                        width: 31px;
                        height: 31px;
                        object-fit: contain;
                        @media (max-width: 1100px) {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
}
