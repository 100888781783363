#explore {
    padding: 75px 0 0 0;
    @media (max-width: 1100px) {
        padding: 60px 0 0 0;
    }
    h3 {
        font-family: 'Butler-Regular';
        font-size: 70px;
        line-height: 70px;
        color: #25224D;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
        small {
            font-family: 'Butler-Regular';
            font-size: 40px;
            line-height: 70px;
            color: #25224D;
            text-transform: uppercase;
        }
        @media (max-width: 1100px) {
            font: normal normal bold 22px/27px "Butler-Regular";
            font-weight: bold;
            small{
                font: normal normal bold 17px/27px "Butler-Regular";
                font-weight: bold;
            }
        }
    }

    .btn-interna {
        display: flex;
        margin: 0 auto;
        max-width: 315px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #D9A521;
        margin-bottom: 66px;
        @media (max-width: 1100px) {
            margin-bottom: 44px;
            font: normal normal bold 18px/21px Raleway;
        }
        svg {
            max-width: 33px;
        }

        svg path {
            fill: #ffffff;
        }

        &:hover {
            background-color: #25224D;
            color: #ffffff;

            svg path {
                fill: #ffffff;
            }
        }
    }

    figure{
        margin: 0;
    }
}
