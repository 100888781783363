.banner {
    min-height: 500px;
    padding-top: 110px;
    position: relative;

    @media (max-width: 1100px) {
        padding-top: 81px;
        padding-bottom: 46px;
        min-height: auto;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(90deg, #212020 0%, #21202000 100%) 0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
        opacity: 0.73;
        z-index: -1;
    }
    .btn-down-mobile{
        display: none;
        @media (max-width: 1100px) {
            display: flex;
        }
    }
    .btn-atrativo:hover {
        transition: .3s ease-in;

        &:hover {
            svg path {
                fill: #d9a521;
                transition: .3s ease-in;
            }
        }
    }

    .banner-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        position: relative;
        z-index: 9;
        @media (max-width: 1100px) {
            padding-inline: 12px;
        }
        .title {
            text-align: left;
            font: normal normal bold 55px/60px "Butler-Regular";
            letter-spacing: 0px;
            color: #FFFFFF;

            @media (max-width: 1100px) {
                font: normal normal bold 30px/35px "Butler-Regular";
            }
        }

        .text {
            text-align: left;
            font-family: 'Raleway';
            font-weight: medium;
            letter-spacing: 0px;
            color: #FEFEFE;
            font-size: 22px;

            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

}
