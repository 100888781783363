/*------------------------- Containers -----------------------*/

.termsfeed-com---nb .cc-nb-main-container {}

.termsfeed-com---palette-light.termsfeed-com---nb {
    box-shadow: 0px 3px 10px #00000029;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
    background-color: white;
}

/*-------------------------TEXTS -----------------------*/


/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
    background: #997A5D;
    transition: 0.7s;
    color: #fff;
    margin-right: 1rem !important;

    &:hover {
        background: #D2A771;
    }
}

.termsfeed-com---palette-dark .cc-nb-reject {
    color: #fff;
    background: #997A5D;
    margin-right: 1rem !important;

    &:hover {
        background: #D2A771;
    }
}

.termsfeed-com---nb-simple {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 2vh;
    border: 0.2px solid #ffffff;
    transition: 0.4s;

}

// Accept
.termsfeed-com---palette-light .cc-nb-okagree {
    background: #997A5D !important;
    margin-right: 1rem !important;
    transition: 0.3s ease-in;

    &:hover {
        background: #D2A771 !important;
        transition: 0.3s ease-in;
    }

    border-radius: 0px !important;
    padding: 10px 20px !important;
}

// Decline
.termsfeed-com---palette-light .cc-nb-reject {
    background: #997A5D !important;
    margin-right: 1rem !important;
    transition: 0.3s ease-in;

    &:hover {
        background: #D2A771 !important;
        transition: 0.3s ease-in;
    }

    border-radius: 0px !important;
    padding: 10px 20px !important;
}

// Config
.termsfeed-com---palette-light .cc-nb-changep {
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
