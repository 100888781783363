#page404 {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    .title {
        text-align: left;
        font: normal normal normal 56px/63px "Butler-Regular";
        letter-spacing: 0px;
        color: #D2A771;
        @media (max-width: 1100px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .subtitle {
        text-align: center;
        font: normal normal normal 45px/45px "Butler-Regular";
        letter-spacing: 0px;
        color: #997A5D;
        @media (max-width: 1100px) {
            font-size: 22px;
            line-height: 25px;
        }
    }

    .text {
        color: var(--unnamed-color-707070);
        text-align: center;
        font: normal normal medium 24px/30px Raleway;
        letter-spacing: 0px;
        color: #707070;
        @media (max-width: 1100px) {
            font: normal normal medium 14px/30px Raleway;
        }
    }

    .backHome {
        text-align: left;
        font: normal normal bold 18px/21px Raleway;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding: 20px 40px;
        background: #997A5D 0% 0% no-repeat padding-box;
        text-decoration: none;
        transition: all .3s ease-out;
        border: 1px solid #997A5D;
        @media (max-width: 1100px) {
            width: calc(100% - 48px);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover {
            background-color: #D2A771 !important;
            border-color: #D2A771 !important;
        }
    }
}
