.banner-interna {
    padding: 180px 0 91px 0;
    position: relative;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        padding-left: 20px;
        z-index: 2;
        position: inherit;
    }

    .heading {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: flex;
        flex-direction: column;
    }

    .heading>div {
        overflow: hidden;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(2)>p {
        -webkit-animation-delay: 0.05s;
        animation-delay: 0.05s;
    }

    div.heading>div {
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }

    div.heading>div:nth-child(3)>p {
        -webkit-animation-delay: 0.075s;
        animation-delay: 0.075s;
    }

    .slide-up {
        -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
        animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
    }

    @-webkit-keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-up {
        0% {
            transform: translateY(100px);
        }

        100% {
            transform: translateY(0);
        }
    }

    h1 {
        font-family: 'Butler-Bold';
        font-size: 66px;
        line-height: 60px;
        color: #FFFFFF;
        margin-bottom: 42px;
    }

    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 27px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .scroll-link {
        display: inline-block;
        margin-top: 80px;
        transition: .3s ease-in;

        &:hover {
            svg path {
                fill: #d9a521;
                transition: .3s ease-in;
            }
        }
    }

    .layer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #00000094;
        z-index: 1;
    }
}

#eventos {
    p {
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #707070;
    }

    .section1 {
        padding: 93px 0 0 0;
        position: relative;

        @media (max-width: 1100px) {
            padding-top: 32px;
            padding-bottom: 0;
        }

        .title-interna {
            padding-left: 123px;
            margin-top: 70px;
            padding-top: 94px;
            margin-bottom: 68px;
            color: #997A5D;

            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                font: normal normal bold 22px/25px "Butler-Regular";
                font-weight: bold;
                margin-top: 34px;
                margin-bottom: 24px;
            }
        }

        p {
            padding-left: 123px;
            max-width: 670px;
            margin-bottom: 32px;

            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .imagem-section {
            width: 1280px;
            height: 875px;
            position: absolute;
            left: -114px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 1100px) {
                width: 100%;
                height: 209px;
                position: relative;
                left: inherit;
            }
        }

        .conteudo-section {
            margin-left: 31px;
            padding: 0;
            position: relative;
            background: #ffffff;
            z-index: 99;
            padding-bottom: 53px;

            @media (max-width: 1100px) {
                padding: 0;
                margin: 0;
                padding-inline: 12px;
            }
        }
    }

    #explore {
        padding: 72px 0 0 0;

        @media (max-width: 1100px) {
            padding-top: 20px;
        }

        h3 {
            color: #997A5D;
            line-height: 59px;
            margin-bottom: 35px;

            small {
                color: #997A5D;
            }

            @media (max-width: 1100px) {
                font: normal normal bold 22px/27px "Butler-Regular";
                font-weight: bold;

                small {
                    font: normal normal bold 17px/27px "Butler-Regular";
                    font-weight: bold;
                }
            }
        }

        .btn-interna {
            background-color: #997A5D;
            margin-bottom: 102px;

            &:hover {
                background-color: #25224D;
            }
        }

        img {
            height: 385px;
            object-fit: cover;
        }
    }

    .section2 {
        padding: 93px 0 128px 0;
        position: relative;

        @media (max-width: 1100px) {
            padding-top: 32px;
            padding-bottom: 100px;
        }

        .title-interna2 {
            color: #997A5D;
            margin-left: 5px;
            margin-bottom: 101px;

            @media (max-width: 1100px) {
                margin-bottom: 30px;
                margin-left: 0;
                text-align: center;
                font: normal normal bold 22px/25px "Butler-Regular";
                font-weight: bold;
            }
        }

        .facilidades {

            .prev {
                position: absolute;
                top: 96px;
                right: 201px;
                cursor: pointer;

                @media (max-width: 1100px) {
                    top: inherit;
                    right: 53vw;
                    cursor: pointer;
                    bottom: 35px;
                }
            }

            .next {
                position: absolute;
                top: 96px;
                right: 121px;
                cursor: pointer;

                @media (max-width: 1100px) {
                    right: 33vw;
                    cursor: pointer;
                    top: inherit;
                    bottom: 35px;
                }
            }

            .row,
            .col-lg-6 {
                padding: 0;
                margin: 25px 0;

                @media (max-width: 1100px) {
                    margin: 0;
                }
            }

            .item {
                max-width: 593px;
                height: 440px;
                padding: 73px 35px 28px 35px;
                -webkit-box-shadow: 10px 10px 20px #0000000D;
                -moz-box-shadow: 10px 10px 20px #0000000D;
                box-shadow: 10px 10px 20px #0000000D;

                @media (max-width: 1100px) {
                    max-width: 100%;
                    height: auto;
                    padding-top: 30px;
                }

                ul {
                    li {
                        font-family: 'Raleway';
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 26px;
                        color: #707070;
                        display: flex;
                        gap: 29px;
                        padding-top: 0;
                        margin-bottom: 26px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 19px;
                        }

                        img {
                            width: 31px;
                        }
                    }
                }
            }

            .slick-slide {
                height: auto !important;
            }
        }
    }

    .section3 {
        padding: 115px 0 170px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1100px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .title-interna2 {
            text-align: center;
            margin-bottom: 95px;

            @media (max-width: 1100px) {
                margin-bottom: 0;
            }
        }

        .planta-baixa {
            margin-top: 97px;
        }

        .planta {
            margin-top: 170px;
        }

        .box_plant {
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .f-panzoom {
            background-color: transparent !important;
        }

        .mobile-title-planta {
            font: normal normal bold 17px/55px "Butler-Regular";
            color: #fff;
            background: #D1A670;
            height: 60px;
            padding-left: 19px;
            display: flex;
            align-items: center;
            justify-content: start;
            margin: 0 auto;
            margin-top: 54px;
            position: relative;
            margin-bottom: 25px;
            width: calc(100% - 48px);

            &::after {
                content: '';
                width: 100%;
                height: 1px;
                background: #fff;
                position: absolute;
                top: -23px;
                left: 0;
            }
        }

        .mobile-planta-baixa {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 40px;
            width: 100%;
            padding-inline: 24px;
            column-gap: 1rem;
        }

        .item-plant-baixa {
            display: flex;
            flex-direction: column;
            gap: 30px;
            position: relative;

            &:nth-child(odd) {
                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 75%;
                    background: #fff;
                    top: 25%;
                    right: -8px;
                }
            }

            img {
                width: 42px;
                height: 42px;
                object-fit: contain;
                margin-left: 18px;
            }

            .title {
                font: normal normal bold 17px/55px "Butler-Regular";
                color: #fff;
                font-weight: bold;
                height: 45px;
                display: flex;
                align-items: center;
                padding-left: 19px;
                background: #d1a5703b 0% 0% no-repeat padding-box;
            }

            .text {
                font-family: 'Raleway';
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }

        .diferencial-mobile {
            padding-inline: 24px;
            margin-top: 40px;

            .title {
                font: normal normal bold 17px/55px "Butler-Regular";
                height: 45px;
                padding-left: 19px;
                background: #d1a5703b 0% 0% no-repeat padding-box;
                color: #fff;
                font-weight: bold;
            }

            p {
                font-size: 14px;
                font-family: 'Raleway';
                color: #fff;
                margin: 0;

                span {
                    color: #D1A670;
                }
            }
        }

    }


    /**/

    #img-zoomer-box {
        width: 100%;
        height: auto;
        position: relative;
        margin-top: 10px;
    }

    #img-1 {
        width: 100%;
        height: auto;
    }

    #img-zoomer-box:hover,
    #img-zoomer-box:active {
        cursor: zoom-in;
        display: block;
    }

    #img-zoomer-box:hover #img-2,
    #img-zoomer-box:active #img-2 {
        opacity: 1;
    }

    #img-2 {
        width: 340px;
        height: 340px;
        /*background: url('https://homologacao.weedev.com.br/projetos/2023/boulevard-convention/public/userfiles/imagens/event/boulevard-mapa-eventos_png-1748px-726px-2167x654.webp') no-repeat #FFF;*/
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
        pointer-events: none;
        position: absolute;
        opacity: 0;
        border: 4px solid whitesmoke;
        z-index: 99;
        display: block;
        transition: opacity .2s;
        transform: scale(1.5);
    }

    .wpp-float{
        position: fixed;
        bottom: 30px;
        right: 30px;
        max-width: 70px;
        z-index: 9999;
    }
}
